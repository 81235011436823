const moment = require('moment');
const _ = require('lodash');
const EnvReportInterface = require('./envReportInterface');
const AllMappingDimensions = require('../mappingDimensions/allMappingDimension');
const DateUtils = require('../misc/dateUtils');
const Constants = require('./constants');
const TrendData = require('./trendData');
const AllCustomizers = require('./customizers/allCustomizers');
const { getCompareToRange } = require('./utils');

const REPORT_KEYS = [
	'start',
	'end',
	'granularity',
	'groupBy',
	'whereEqual',
	'whereIn',
	'sums',
	'mergePublicHidden',
	'splitByAuctionRunner',
	'maxAdvertisers',
	'includeNoUserAdvertisers',
	'currency',
	'useForecast',
	'correctionType',
	'filterFlags',
	'attributes',
	'filterSelectionType',
	'timezone',
	'warnOldData',
	..._.map(AllMappingDimensions, 'reportProp'),
];

const { COMP_PFX } = Constants;

class ReportData {
	constructor(settings, compareToRange, now, user) {
		const {
			type, sums, copyAllFromSettings, timezone,
		} = settings;
		if (copyAllFromSettings) {
			Object.assign(this, settings);
			return;
		}
		this.fnName = Constants[type].API_FN;
		const CALC_SUMS = Constants[type].CALCULATED_SUMS || {};
		const calcSums = Object.keys(CALC_SUMS).filter((s) => _.includes(sums, s));
		const allSumsWithDupl = _.uniq(_.flatten(sums.concat(calcSums.map((c) => CALC_SUMS[c].requires))));
		Object.assign(this, {
			settings,
			customizer: AllCustomizers.createByType(type, this),
			compareToRange,
			serverSums: _.difference(allSumsWithDupl, Object.keys(CALC_SUMS)),
			transformSums: calcSums.filter((s) => CALC_SUMS[s].trendMetric),
			CALC_SUMS,
			calcSums,
			calcSumsMap: _.pick(CALC_SUMS, calcSums),
			now: DateUtils.timezoneOffsetDate(now, timezone),
			user,
		});
	}

	static async from(settings, now, user) {
		const { useCompareTo } = settings;
		const reportData = new ReportData(settings, useCompareTo
			? getCompareToRange(settings)
			: null, now, user);
		await reportData.runReport();
		return reportData;
	}

	getConstants() {
		const { type } = this.settings;
		return Constants[type];
	}

	isTrendMetric(metric) {
		return this.transformSums.indexOf(metric) >= 0;
	}

	async runReport() {
		const ctx = { calls: [], reportData: this };
		const { start, end } = this.settings;
		const baseParams = Object.assign(_.pick(this.settings, REPORT_KEYS), {
			start: DateUtils.toDate(start, this.now),
			end: DateUtils.toDate(end, this.now),
			sums: this.serverSums,
			disableReportCache: typeof location !== 'undefined' && location.toString().indexOf('disableReportCache') >= 0,
		});
		baseParams.filterSelectionType = _.pickBy(baseParams.filterSelectionType, (v) => v !== 'INCLUSIVE');
		if (_.isEmpty(baseParams.filterSelectionType)) {
			baseParams.filterSelectionType = undefined;
		}
		baseParams.filterSelectionType = _.isEmpty(baseParams.filterSelectionType) ? undefined : baseParams.filterSelectionType;

		const params = [baseParams];
		if (this.compareToRange) {
			params.push({
				...baseParams,
				start: this.compareToRange.start,
				end: this.compareToRange.end,
			});
		}
		const results = await Promise.all(params.map((p) => this.requestReport(p, ctx)));
		Object.assign(this, results[0]);
		if (this.compareToRange) {
			this.mergeWithCompReport(results[1].report);
		}
		this.attribsByLabel = {};
		const attributes = _.pickBy(this.report.attributes, (v) => !_.isEmpty(v));
		_.forOwn(attributes, (byKey, dim) => {
			const dst = {};
			this.attribsByLabel[dim] = dst;
			const labels = this.report.labels[dim] || {};
			_.forOwn(byKey, (attribs, key) => {
				const label = labels[key];
				if (labels) {
					dst[label] = attribs;
				}
			});
		});
		// Pick these fields from whatever api-calls that returned them
		Object.assign(this, ...ctx.calls.map((c) => _.pick(c.response, ['dataTooOld', 'dataTooOldLimit'])));
	}

	getCompDayDiff() {
		const { start } = this.settings;
		return moment.utc(DateUtils.toDate(start, this.now)).diff(this.compareToRange.start, 'days');
	}

	mergeWithCompReport(compReport) {
		const { groupBy, granularity } = this.settings;
		const shortRange = Constants.SHORT_TIME_RANGES[granularity];
		const dayDiff = this.getCompDayDiff();
		const withNormalizedKeys = (obj, isDate) => {
			if (!obj || !isDate) {
				return obj;
			}
			return _.mapKeys(obj, (v, k) => (
				shortRange ? DateUtils.addDays(k, dayDiff) : DateUtils.fullDay(k, dayDiff)
			));
		};
		const copyCompareData = (obj, idx) => {
			const isDate = groupBy[idx] === 'date';
			const res = withNormalizedKeys(obj, isDate);
			if (idx !== groupBy.length) {
				return _.mapValues(res, (v) => copyCompareData(v, idx + 1));
			}
			_.forOwn(res, (v, k) => {
				res[`${COMP_PFX}${k}`] = v;
				res[k] = 0;
			});
			return res;
		};
		const run = (data, compDataObj, idx) => {
			let compData = compDataObj;
			if (idx === groupBy.length) {
				_.forOwn(data, (v, k) => {
					data[`${COMP_PFX}${k}`] = compData ? compData[k] || 0 : 0;
				});
			} else {
				const isDate = groupBy[idx] === 'date';
				compData = withNormalizedKeys(compData, isDate);
				const usedCompKeys = {};
				_.forOwn(data, (v, k) => {
					let compObj;
					if (compData) {
						compObj = compData[k];
						usedCompKeys[k] = true;
					}
					run(v, compObj, idx + 1);
				});
				_.forOwn(compData, (v, k) => {
					if (!usedCompKeys[k] && !(isDate && new Date(k) > this.now)) {
						data[k] = copyCompareData(v, idx + 1);
					}
				});
			}
		};
		run(this.report.data, compReport.data, 0);
		this.report.labels = _.merge(compReport.labels, this.report.labels);
	}

	calculate(dataObj, sumVal, useComp) {
		const calcSum = this.calcSumsMap[sumVal];
		let obj = dataObj;
		if (calcSum) {
			if (useComp) {
				obj = {};
				for (const key in dataObj) {
					if (key.startsWith(COMP_PFX)) {
						obj[key.slice(COMP_PFX.length)] = dataObj[key];
					}
				}
			}
			if (calcSum.fn) {
				return calcSum.fn(obj);
			}
			if (this.trendData && calcSum.trendMetric) {
				return this.trendData.calculate(obj, sumVal, calcSum);
			}
		}
		return obj[useComp ? `${COMP_PFX}${sumVal}` : sumVal];
	}

	allDataSums() {
		const res = this.serverSums.concat(this.trendData ? this.trendData.extraSums : []);
		return this.compareToRange ? res.concat(res.map((k) => `${COMP_PFX}${k}`)) : res;
	}

	async internalRequestReport(reportParams, ctx) {
		const transformedParams = this.customizer.transformedReportParams(reportParams, ctx);
		const callFn = async (params) => {
			const reportCallFn = this.settings.reportCallFn || ((...args) => EnvReportInterface.callReport(...args));
			const response = await reportCallFn(this.fnName, params, this.user);
			if (ctx) {
				ctx.calls = ctx.calls || [];
				ctx.calls.push({ params, response });
			}
			return response;
		};
		let res = await this.customizer.performCall(transformedParams, callFn);
		res = this.customizer.transformedReportResult(res, ctx);
		const {
			start, end, groupBy, sums, useForecast,
		} = reportParams;
		const { granularity } = this.settings;
		const shortRange = Constants.SHORT_TIME_RANGES[granularity];
		const empty = _.last(groupBy) === 'date' ? _.zipObject(sums, Array(sums.length).fill(0)) : {};
		let allDates;
		if (shortRange) {
			allDates = DateUtils.datesByMsPeriod(start, end, shortRange.ms, !useForecast, this.now);
		} else {
			allDates = DateUtils.dates(start, end);
		}
		allDates = allDates.map((d) => d.toString());
		const fullCache = {};

		const normalizeDates = (data, level) => {
			if (groupBy[level] === 'date') {
				const normalized = {};
				_.forOwn(data, (val, key) => {
					const dateStr = (fullCache[key] = fullCache[key] || this.customizer.normalizeDate(key));
					normalized[dateStr] = val;
				});
				allDates.forEach((date) => {
					if (!normalized[date]) {
						normalized[date] = { ...empty };
					}
				});
				return normalized;
			}
			_.forOwn(data, (v, k) => {
				data[k] = normalizeDates(v, level + 1);
			});
			return data;
		};
		if (groupBy.indexOf('date') >= 0) {
			res.data = normalizeDates(res.data, 0);
		}
		return res;
	}

	async requestReport(reportParams, ctx) {
		let res;
		let trendData;
		const { trendMethod, trendPeriods } = this.settings;
		if (this.transformSums.length) {
			trendData = new TrendData({
				apiParams: reportParams,
				trendMetrics: _.pick(this.CALC_SUMS, this.transformSums),
				trendMethod,
				trendPeriods,
			});
			const paramArr = trendData.splitReportParams();
			const resArr = await Promise.all(paramArr.map((p) => this.internalRequestReport(p, ctx)));
			res = _.last(resArr);
			trendData.processReports({
				report: res,
				prevReports: _.initial(resArr),
			});
		} else {
			res = await this.internalRequestReport(reportParams, ctx);
		}
		if (EnvReportInterface.getGenericData().OBSCURE_NAMING_ENABLED) {
			const { sourceId } = res.labels;
			let idx = 0;
			_.forOwn(sourceId || {}, (val, key) => {
				sourceId[key] = `Demo hidden id ${++idx}`;
			});
		}
		return { report: res, trendData };
	}
}

module.exports = ReportData;
