import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AiState {
  isPinned: boolean;
  setIsPinned: (pinned: boolean) => void;
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	pinnedWidth: number;
	setPinnedWidth: (width: number) => void;

	isPinnedAndOpen: boolean;
	sendMessage: (message: string, context?: object) => Promise<void>;
	setSendMessage: (ref) => void;
}

export const useAiStore = create<AiState>()(
	persist(
		(set) => ({
			isPinned: false,
			setIsPinned: (pinned) => set((state) => ({
				isPinned: pinned,
				isPinnedAndOpen: state.isOpen && pinned,
			})),
			isOpen: false,
			setIsOpen: (open) => set((state) => ({
				isOpen: open,
				isPinnedAndOpen: state.isPinned && open,
			})),
			pinnedWidth: 500,
			setPinnedWidth: (width) => set(() => ({
				pinnedWidth: width,
			})),
			isPinnedAndOpen: false,
			sendMessage: () => Promise.resolve(),
			setSendMessage: (ref) => set({ sendMessage: (...args) => ref?.current?.(...args) }),
		}),
		{
			name: 'ai-storage',
		},
	),
);
