import { useEffect, useRef, useState } from 'react';

/**
 * Like useState, but provides an extra `shouldRender` state that can be used to control when the component should be rendered.
 * This is useful when using CSS transitions to hide and show something, but you still want it to be mounted/unmounted along with the transition.
 * Without delaying the unmounting, the browser will skip the transition out, causing the component to disappear without the transition.
 * Likewise, when transitioning in, the without mounting the component BEFORE the transition starts, the transition will not work.
 * @param initialVisible - The initial state of the component.
 * @param duration - The duration of the transition in milliseconds.
 * @returns An array containing the current state, a function to toggle the state, and a boolean indicating whether the component should be rendered.
 */
export function useVisibilityState(initialVisible: boolean, duration: number): [boolean, (show: boolean) => void, boolean] {
	const [shouldRender, setShouldRender] = useState(initialVisible);
	const [visible, setVisible] = useState(initialVisible);
	const timeout = useRef<number | null>(null);

	const toggle = (show: boolean) => {
		if (show) {
			setShouldRender(true);
			// Wait for next frame to ensure element is rendered before transition
			requestAnimationFrame(() => {
				setVisible(true);
			});
		} else {
			setVisible(false);
			timeout.current = setTimeout(() => setShouldRender(false), duration);
		}
	};

	useEffect(() => {
		return () => clearTimeout(timeout.current);
	}, []);

	return [
		visible,
		toggle,
		shouldRender,
	];
}
