import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	IconButton,
	Tooltip,
	CircularProgress,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { Reports } from '../../api/relevant';
import useDebounce from '../../hooks/useDebounce';
import { useMutation, useQueryClient } from '@tanstack/react-query';

function PinButton({ pinned: initialPinned, reportId, disabled }) {
	const [pinned, setPinned] = useState(initialPinned);

	const queryClient = useQueryClient();
	const mutate = useMutation({
		mutationFn: async (pinned) => {
			await Reports.call('updatePinnedToSidebar', {
				reportIds: [reportId],
				pinned,
			});
			setPinned(pinned);
		},
		onSettled: async () => {
			await queryClient.invalidateQueries('reports');
		}
	});

	// Don't display loading button if action takes < 250ms to avoid stuttering
	const debouncedIsLoading = useDebounce(mutate.isPending, 250);

	return (
		<Tooltip title={pinned ? 'Unpin from sidebar' : 'Pin to sidebar'}>
			<span>
				<IconButton
					disabled={mutate.isLoading || disabled}
					aria-label="pin"
					onClick={() => mutate.mutate(!pinned)}
				>
					{ debouncedIsLoading && (
						<CircularProgress
							size={24}
						/>
					)}
					{(pinned && !debouncedIsLoading) && <PushPinIcon />}
					{!pinned && !debouncedIsLoading && <PushPinOutlinedIcon />}
				</IconButton>
			</span>
		</Tooltip>
	);
}

PinButton.propTypes = {
	pinned: PropTypes.bool.isRequired,
	reportId: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

PinButton.defaultProps = {
	disabled: false,
};

export default PinButton;
