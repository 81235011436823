import React, { useEffect,
	useState,
	useRef,
	Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { Alert, Collapse } from '@mui/material';
import Modal from '../Modal';
import useRequest from '../../hooks/useRequest/useRequest';
import { relevant } from '../../api';
import { UserIdModule } from '../../api/relevant';
import SimpleOperationWrapper from '../SimpleOperationWrapper';
import useQuery from '../../hooks/useQuery';
import useIsMounted from '../../hooks/useIsMounted';

function UserIdModulesEditor({
	match, backUrl, desc, onChange,
}) {
	const isMounted = useIsMounted();
	const [setRequest, loading, requestError, reset] = useRequest({});
	const urlQuery = useQuery();
	const [availableModules, setAvailableModules] = useState([]);
	const [selectedModule, setSelectedModule] = useState();
	const [name, setName] = useState('');
	const nameTextFieldRef = useRef();

	// 'add' or a user id module object ID
	const id = match.params.id.toLowerCase();

	useEffect(() => {
		if (isMounted.current) {
			setRequest({
				requestFunction: () => {
					if (id !== 'add') {
						return Promise.all([UserIdModule.call('getAvailableModules'), UserIdModule.get(id)]);
					}
					// Array just for simplifying the callback args...
					return Promise.all([UserIdModule.call('getAvailableModules')]);
				},
				requestCallback: ([modules, currentModule]) => {
					if (isMounted.current) {
						setAvailableModules(modules);
						// If editing
						if (currentModule) {
							setName(currentModule.name);
							setSelectedModule(currentModule);
						} else {
							const firstNonGenericModule = modules.find(({ type }) => type !== 'GenericUserIdModule');
							setSelectedModule(firstNonGenericModule);
						}
					}
				},
			});
		}
	}, [isMounted, setRequest, id]);

	const addUserIdModule = async () => {
		const publisherId = urlQuery.get('publisherId');
		const {
			type,
			friendlyTypeName,
			prebidName,
			moduleName,
		} = availableModules.find((m) => m.prebidName === selectedModule.prebidName);

		const payload = {
			name,
			type,
			friendlyTypeName,
			prebidName,
			moduleName,
			...(publisherId && { publisherId }),
		};
		return new Promise((resolve) => setRequest({
			requestFunction: () => UserIdModule.add(payload),
			requestCallback: () => {
				onChange();
				resolve(true);
			},
		}));
	};

	const editUserIdModule = async () => (
		new Promise((resolve) => setRequest({
			requestFunction: () => relevant.put(`/${'useridmodules'}/${id}`, { name }),
			requestCallback: () => {
				onChange();
				resolve(true);
			},
		}))
	);

	const onSubmit = () => {
		if (id === 'add') {
			return addUserIdModule();
		}
		return editUserIdModule();
	};

	const onSelectModule = ({ target }) => {
		const selected = availableModules.find(({ prebidName }) => prebidName === target.value);
		setSelectedModule(selected);
	};

	return (
		<Modal
			open
			title={`${id === 'add' ? 'Add' : 'Edit'} ${desc}`}
			parentPath={backUrl}
			submitButtonProps={{ label: 'Save' }}
			onSubmit={onSubmit}
			dialogProps={{
				maxWidth: 'sm',
				fullWidth: true,
			}}
			dialogContentProps={{ style: { overflowY: 'hidden' } }}
			submittable
		>
			<SimpleOperationWrapper
				loading={loading}
				error={requestError}
				onErrorModalClick={reset}
				loadingContainerProps={{ position: 'static' }}
			>
				<Grid container spacing={4} direction="row">
					{ selectedModule && (
						<>
							<Collapse in={selectedModule && selectedModule.type === 'GenericUserIdModule'} mountOnEnter unmountOnExit>
								<Alert
									severity="warning"
									elevation={0}
									style={{ marginTop: 32, marginLeft: 32 }}
								>
									This is a generic User ID module.
									It may not have all its available Prebid settings, nor work as intended, so use with caution.
								</Alert>
							</Collapse>
							<Grid item xs={6} mt={1}>
								<FormControl fullWidth>
									<InputLabel id="userid-select-label">Type</InputLabel>
									<Select
										labelId="userid-select-label"
										value={selectedModule.prebidName}
										onChange={onSelectModule}
										disabled={id !== 'add'}
										label="Type"
									>
										{ availableModules.map(({ prebidName, friendlyTypeName }) => (
											<MenuItem
												key={prebidName}
												value={prebidName}
											>
												{friendlyTypeName}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6} mt={1}>
								<TextField
									fullWidth
									label="Name"
									onChange={({ target }) => setName(target.value)}
									value={name}
									placeholder={selectedModule?.friendlyTypeName}
									inputRef={nameTextFieldRef}
								/>
							</Grid>
						</>
					)}
				</Grid>
			</SimpleOperationWrapper>
		</Modal>
	);
}

export default UserIdModulesEditor;

UserIdModulesEditor.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
	}).isRequired,
	backUrl: PropTypes.string.isRequired,
	desc: PropTypes.string.isRequired,
	onChange: PropTypes.func,
};

UserIdModulesEditor.defaultProps = {
	onChange: () => undefined,
};
