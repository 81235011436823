/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import '../styles/base.css';
import ProgrammaticPublisherDashboard from '../pages/ProgrammaticPublisherDashboard';
import {
	ProgrammaticUserInvoingPage,
	AdvertiserMappingListPage,
	AdvertiserMappingPage,
	TriggerWorkspacePage,
} from '../pages/SimplePages';
import UserList from '../pages/UserList';
import UserEdit from '../pages/UserEdit';
import { stores } from '../stores';
import { reportRoutes } from '../components/Report/utils';
import Page from '../containers/Page';

export default function Programmatic({ children }) {
	const canEditUsers = stores.identity.canEditUsers();
	return (
		<div className="w-full min-w-0">
			<Switch>
				<Route
					exact
					path="/dashboard"
					render={(props) => (
						<Page
							title="Dashboard"
						>
							<ProgrammaticPublisherDashboard {...props} />
						</Page>
					)}
				/>
				<Route
					exact
					path="/invoicing"
					render={(props) => (
						<Page
							title="Invoices"
						>
							<ProgrammaticUserInvoingPage {...props} />
						</Page>
					)}
				/>

				<Route
					exact
					path="/alarms"
					render={(props) => (
						<Page
							title="Alarms"
						>
							<TriggerWorkspacePage {...props} />
						</Page>
					)}
				/>
				<Route
					exact
					path="/advertiser-mappings"
					render={(props) => (
						<Page
							title="Advertiser mappings"
						>
							<AdvertiserMappingListPage {...props} />
						</Page>
					)}
				/>
				<Route
					exact
					path="/advertiser-mappings/:id"
					render={(props) => (
						<Page
							title="Advertiser mapping"
						>
							<AdvertiserMappingPage {...props} />
						</Page>
					)}
				/>
				{canEditUsers && (
					<Route
						exact
						path="/users"
						render={(props) => (
							<Page
								title="Users"
							>
								<UserList {...props} />
							</Page>
						)}
					/>
				)}
				{canEditUsers && (
					<Route
						exact
						path="/users/:userId"
						render={(props) => (
							<Page
								title="Edit user"
							>
								<UserEdit {...props} />
							</Page>
						)}
					/>
				)}
				{reportRoutes()}
				{/* Routes available for all */}
				{ children }
			</Switch>
		</div>
	);
}

Programmatic.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

Programmatic.defaultProps = {
	children: null,
};
