import React from 'react';
import clsx from 'clsx';

export function Badge({
	color = 'grey',
	emphasized = true,
	className,
	children,
}: {
	color?: 'grey' | 'blue' | 'green' | 'yellow';
	emphasized?: boolean;
	className?: string;
	children: React.ReactNode;
}) {
	return (
		<div className={clsx(
			'inline-block w-fit rounded-[4px] px-2 py-1 text-xs text-night-blue-700',
			{
				'font-semibold': emphasized,
				'bg-grey-100': color === 'grey',
				'bg-night-blue-100': color === 'blue',
				'bg-green-100': color === 'green',
				'bg-yellow-100': color === 'yellow',
			},
			className,
		)}>
			{children}
		</div>
	);
}
