import { HelpOutlineRounded, SendRounded } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';
import { ReactComponent as DeadRobot } from 'assets/icons/DeadRobot.svg?component';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { MiscTasks } from '../../api/relevant';
import { useScrollState } from '../../hooks/useScrollState';
import { useVisibilityState } from '../../hooks/useVisibilityState';
import SystemData from '../../lib/systemData';
import { useAiStore } from '../../stores/AiStore';
import { Badge } from '../Badge';
import { Button } from '../Button/Button';
import { StyledTooltip } from '../StyledTooltip';
import { SmartsMarkdown } from './SmartsMarkdown';

type SmartHelpProps = {
	query: string;
	question: string;
	answer?: string;
	left?: boolean;
	visible?: boolean;
}

export function SmartHelp({
	query,
	question,
	answer: initialAnswer,
	left,
	visible: initialVisible,
}: SmartHelpProps) {
	const [visible, setVisible, shouldRender] = useVisibilityState(!!initialVisible, 300);
	const [answer, setAnswer] = useState(initialAnswer);
	// const [followUp, setFollowUp] = useState<string | undefined>(undefined);
	const { ref: answerRef, isBottom: isScrolledToBottom } = useScrollState();
	const { setIsOpen: aiSetIsOpen, sendMessage: aiSendMessage } = useAiStore();
	const [language] = useLocalStorage<string>('aiLang', 'english');
	const inputRef = useRef<HTMLInputElement>(null);

	const helpQuery = useQuery<{ key: string, text: string, followUp?: string }>({
		queryKey: ['ai', 'help', query],
		queryFn: async () => {
			return await MiscTasks.call('aiHelp', { query, question, language });
		},
		enabled: shouldRender,
		refetchOnWindowFocus: false,
		retry: false,
	});

	useEffect(() => {
		if (helpQuery.data) {
			setAnswer(helpQuery.data.text);
			// setFollowUp(helpQuery.data.followUp);
		}
	}, [helpQuery.data]);

	// Dynamically switch sides of the button based on available space
	const buttonRef = useRef<HTMLButtonElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [showOnLeft, setShowOnLeft] = useState(left);
	useEffect(() => {
		const checkPosition = () => {
			if (shouldRender && buttonRef.current && containerRef.current) {
				const buttonRect = buttonRef.current.getBoundingClientRect();
				const containerRect = containerRef.current.getBoundingClientRect();
				if (left) {
					const spaceOnLeft = buttonRect.left;
					setShowOnLeft(spaceOnLeft < containerRect.width);
				} else {
					const spaceOnRight = window.innerWidth - buttonRect.right;
					setShowOnLeft(spaceOnRight < 500); // 450px width + some margin
				}
			}
		};

		checkPosition();
		window.addEventListener('resize', checkPosition);
		return () => window.removeEventListener('resize', checkPosition);
	}, [shouldRender, left]);

	// Close when clicking outside
	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (!containerRef.current?.contains(e.target as Node) && !buttonRef.current?.contains(e.target as Node)) {
				setVisible(false);
			}
		};
		document.addEventListener('click', handleClick);
		return () => document.removeEventListener('click', handleClick);
	}, [setVisible]);

	const handleSend = () => {
		const message = inputRef.current?.value;
		if (message) {
			aiSendMessage(message, { helpKey: helpQuery.data?.key });
			aiSetIsOpen(true);
			inputRef.current.value = '';
			setVisible(false);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleSend();
		}
	};

	return (
		<div className="inline-flex relative">
			{shouldRender && (
				<div
					ref={containerRef}
					className={clsx(
						'absolute box-border transition-all duration-300 bg-white rounded-lg p-4 shadow-lg border border-gray-200 w-[450px] z-[2005]',
						showOnLeft ? '-left-[460px] -top-[10px]' : 'left-10 -top-[10px]',
						[
							showOnLeft
								? 'before:right-[-8px] before:left-auto before:rotate-[225deg]'
								: 'before:left-[-8px] before:rotate-45',
							'before:absolute before:w-4 before:h-4 before:bg-white before:top-[14px]',
							'before:border-b before:border-l before:border-gray-200 before:shadow-[-3px_3px_3px_rgba(0,0,0,0.1)]',
						],
						visible ? 'opacity-100 translate-x-0 pointer-events-auto' : 'opacity-0 -translate-x-3 pointer-events-none',
					)}
				>
					{/* Error overlay */}
					{helpQuery.isError && (
						<div className={clsx(
							'absolute text-cherry-600 inset-0 flex flex-col items-center justify-center text-center backdrop-blur-sm bg-white/50 z-[1] animate-fade-in',
						)}>
							<DeadRobot className="size-20 text-cherry-600 -mt-16 rotate-12" />
							{/* <span className="font-medium text-lg">Ouch!</span> */}
							<p className="text-base text-grey-500 mt-4 px-4 leading-relaxed">
								Sorry, we failed to fetch the latest information.
								<br />
								You may have better luck finding it in our <span className="font-medium">manual</span> instead!
							</p>
							<Button
								variant="outlined"
								href={`https://help.relevant-digital.com/knowledge/kb-search-results?term=${query}`}
								className="mt-6"
							>
								Relevant Yield Manual
							</Button>
						</div>
					)}
					<div className="flex flex-col justify-between">
						<div className="relative">
							<Badge color="yellow">AI Help</Badge>

							{/* Answer */}
							<div
								ref={answerRef}
								className="text-sm text-grey-600 h-[400px] w-full overflow-y-auto pr-1 [mask-image:linear-gradient(to_bottom,black_calc(100%-2rem),transparent_100%)] block"
							>
								{/* Skeleton while loading */}
								{!helpQuery.isSuccess && (
									<>
										<h1 className={clsx(
											'bg-grey-200 rounded-md text-lg text-transparent font-medium my-2',
											helpQuery.isFetching && 'animate-pulse',
										)}>
											Lorem ipsum
										</h1>
										{/* This has placeholder content cause it's the easiest way to create a multiline box that looks like text with gaps in between lines :) */}
										<span className={clsx(
											'inline bg-grey-200 rounded-sm text-transparent my-2',
											helpQuery.isFetching && 'animate-pulse',
										)}>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
										</span>
									</>
								)}
								<div className={clsx(
									'transition-opacity duration-500',
									helpQuery.isLoading ? 'opacity-0' : 'opacity-100',
								)}>
									<SmartsMarkdown>
										{answer}
									</SmartsMarkdown>
								</div>
							</div>

							{/* Fade-out gradient when overflowing */}
							<div
								className={clsx(
									'absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none transition-opacity duration-100',
									isScrolledToBottom && 'opacity-0',
								)}
							/>
						</div>

						{/* Input */}
						{SystemData.genericData.aiEnabled && (
							<div className="pt-3 border-gray-200">
								<div className="relative">
									<input
										ref={inputRef}
										type="text"
										onKeyDown={handleKeyDown}
										className="w-full rounded-md border border-gray-300 px-3 py-2 pr-10 text-gray-700 placeholder-gray-400 focus:border-black focus:placeholder-transparent focus:outline-none"
										placeholder={helpQuery.isSuccess ? 'Ask Relevant AI' : ''}
										disabled={!helpQuery.isSuccess}
									/>
									<button
										type="button"
										onClick={handleSend}
										className="absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer"
										disabled={!helpQuery.isSuccess}
									>
										<SendRounded className="text-blue-500 hover:text-blue-600" />
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			)}

			<StyledTooltip title="Documentation">
				<button
					ref={buttonRef}
					type="button"
					onClick={() => {
						setVisible(!visible);
						// Re-fetch on click in case pre-fetching fails for some reason
						if (!helpQuery.isSuccess) {
							helpQuery.refetch();
						}
					}}
					onMouseOver={() => {
						// Pre-fetch on hover to make it feel more responsive
						if (!helpQuery.isFetched) {
							helpQuery.refetch();
						}
					}}
					onFocus={() => {
						if (!helpQuery.isFetched) {
							helpQuery.refetch();
						}
					}}
					className={clsx(
						'inline-flex border border-transparent rounded-full transition-all items-center justify-center',
						'text-grey-500 hover:text-grey-600 hover:bg-cherry-100 active:text-cherry-600 active:border-cherry-600 disabled:text-grey-450',
					)}
				>
					<HelpOutlineRounded />
				</button>
			</StyledTooltip>
		</div>
	);
}
