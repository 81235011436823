const customReporting = 'Custom parameters for reporting';
const adsReporting = 'Adserver Key-values for reporting';

module.exports = [
	{
		type: 'Object',
		description: 'PubX Optimization',
		name: 'rlvPubx',
		omitWhenDefault: true,
		fields: [
			{
				type: 'String',
				name: 'pubxId',
				description: 'PubX publisher id',
				defaultValue: '957caee5-5a88-4363-82a4-251da7b5e35c',
			},
			{
				type: 'Number',
				name: 'perc',
				description: 'Enable for % of users',
				defaultValue: 0,
				omitWhenDefault: true,
				props: {
					between: { low: 0, high: 100 },
					help: 'Set to 100 if you want PubX optimization for all users',
				},
			},
			{
				type: 'String',
				name: 'strategy',
				description: 'Loading strategy',
				defaultValue: 'standard',
				isRequired: true,
				omitWhenDefault: true,
				hasOptions: true,
				options: [
					{
						name: 'standard',
						label: 'Load floors via Prebid.js',
					},
					{
						name: 'hybrid',
						label: 'Load floors directly',
					},
				],
			},
			{
				type: 'Number',
				name: 'maxAge',
				description: 'Used cached floors when needed if age is less than (hours)',
				shouldHide: (ctx, field, { strategy }) => strategy.value !== 'hybrid',
			},
			{
				type: 'Number',
				name: 'timeout',
				description: 'Max extra auction delay for PubX in milliseconds',
				defaultValue: 100,
			},
			{
				type: 'Boolean',
				name: 'customEnabled',
				description: 'Send "PubX Enabled"',
				omitWhenDefault: true,
				uiGroup: customReporting,
			},
			{
				type: 'Boolean',
				name: 'customStatus',
				description: 'Send "PubX Status"',
				omitWhenDefault: true,
				uiGroup: customReporting,
			},
			{
				type: 'Boolean',
				name: 'adsEnabled',
				description: 'Send "rlv_pubx_enabled"',
				omitWhenDefault: true,
				uiGroup: adsReporting,
			},
			{
				type: 'Boolean',
				name: 'adsStatus',
				description: 'Send "rlv_pubx_status"',
				omitWhenDefault: true,
				uiGroup: adsReporting,
			},
		],
	},
];
