/* eslint-disable react/prop-types */
import React from 'react';
import {
	Grid,
} from '@mui/material';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import TextEditor from './textEditor';
import ExpandSelector from '../ExpandSelector';
import FieldArray from './fieldArray';
import FieldDataObject from './fieldDataObject';
import FieldDataArray from './fieldDataArray';
import { CheckboxSign } from '../Wrappers';
import Form from '../../containers/Form';
import PrebidBuildOptions from '../PrebidBuild/PrebidBuildOptions';
import StringType from './stringType';
import ByObjectType from './byObjectType';
import ObjectData from './objectData';

const numConvert = (val) => {
	const num = parseFloat(val);
	return Number.isNaN(num) ? null : num;
};

const codeType = ({ title, mode }) => ({
	defaultValue: '',
	canInnerDisable: true,
	render: (p) => (
		<ExpandSelector title={p.label}>
			<TextEditor mode={mode} width="100%" {...(p.field(p.name))} />
		</ExpandSelector>
	),
	renderData: (p) => (
		<ExpandSelector title={title} expanded={p.expand} form={Form.fromField(p.field)}>
			<TextEditor
				mode={mode}
				width="100%"
				{...(p.field('value'))}
				required={p.isRequired}
				readOnly={p.inDisabled}
				style={{ opacity: p.inDisabled ? 0.5 : 1 }}
			/>
		</ExpandSelector>
	),
	renderSummary: ({ value }) => (
		<span style={{ fontWeight: 'bold', color: value.value ? 'green' : 'black' }}>
			{value ? `${value.value.length} chars` : 'Empty'}
		</span>
	),
});

const ByType = {
	String: StringType,
	Number: {
		inlineRender: true,
		defaultValue: 0,
		render: (p) => (
			<TextField
				{...(p.field(p.name))}
				label={p.label}
				float
				fullWidth
				margin="normal"
				filter={numConvert}
			/>
		),
		renderData: (p) => (
			<TextField
				{...(p.field('value'))}
				label={p.description}
				hideLabelIfEmpty
				float
				required={p.isRequired}
				fullWidth
				margin="none"
				filter={numConvert}
				disabled={p.inDisabled}
				{...p.value.props}
			/>
		),
		renderSummary: ({ value }) => <span style={{ fontWeight: 'bold' }}>{value.value}</span>,
	},
	JavaScript: codeType({ title: 'Code', mode: 'javascript' }),
	HTML: codeType({ title: 'HTML', mode: 'html' }),
	Boolean: {
		inlineRender: true,
		defaultValue: false,
		render:	(p) => <Checkbox {...(p.field(p.name))} label={p.label} textFieldAlign />,
		renderData:	(p) => (
			<Checkbox
				disabled={p.inDisabled}
				label={p.description}
				{...p.value.props}
				{...(p.field('value'))}
			/>
		),
		renderSummary: ({ value }) => {
			if (value.value) {
				return (
					<CheckboxSign />
				);
			}
			return (
				<CheckboxSign
					style={{ color: 'red', top: -6, fontSize: 20 }}
					content="✕"
				/>
			);
		},
	},
	Array: {
		defaultValue: [],
		isContainer: true,
		canInnerDisable: true,
		render: (p) => <FieldArray model={p.model} field={p.field} inArray />,
		renderData: (p) => (
			<ExpandSelector title="Elements" expanded={p.expand} form={Form.fromField(p.field)}>
				<div sx={{ opacity: p.addDisabler ? 0.5 : 1 }}>
					<FieldDataArray
						model={p.value}
						field={p.field}
						inDisabled={p.inDisabled}
						renderContext={p.renderContext}
					/>
				</div>
			</ExpandSelector>
		),
		renderSummary: (p) => (
			<ExpandSelector title={p.value.description} expanded>
				<FieldDataArray
					model={p.value}
					field={p.field}
					summary
				/>
			</ExpandSelector>
		),
	},
	Object: {
		defaultValue: {},
		isContainer: true,
		canInnerDisable: true,
		render: (p) => <FieldArray model={p.model} field={p.field} inArray={!!p.inArray} />,
		renderData: (p) => <ObjectData {...p} />,
		renderSummary: (p) => (
			<ExpandSelector title={p.value.description} expanded>
				<Grid container spacing={3}>
					<FieldDataObject
						model={p.value.value}
						field={(path) => p.field(`value.${path}`)}
						summary
					/>
				</Grid>
			</ExpandSelector>
		),
	},
	ByObject: ByObjectType,
	PrebidBuild: {
		isInternalType: true, // Users can't create fields of this type
		inlineRender: true,
		isContainer: false,
		canInnerDisable: true,
		style: { minWidth: 220 },
		// 36 = height of sub options (manage builds etc) + sub options padding + the padding we want below (10px)
		cardStyle: { paddingBottom: 36, paddingTop: 20 },
		typographyStyle: { marginRight: 0 },
		renderData: ({ field, addDisabler }) => (
			<PrebidBuildOptions disabled={addDisabler} field={field('value')} />
		),
		render:	() => null,
		renderSummary: () => null,
	},
};

export default ByType;
