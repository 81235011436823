/** Class that represents the dimensions in a single report used when updating HBA-data from adserver API-data.
 * It will contain GranularDimension instances and .id must be unique within a GranularDimensionSet. */
class GranularDimensionArr extends Array {
	constructor(id, dims) {
		if (!dims && typeof id === 'number') { // to support .map() etc
			super(id);
		} else {
			super(...dims);
			this.id = id;
		}
	}
}

module.exports = GranularDimensionArr;
