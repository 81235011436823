/* eslint-disable react/no-unstable-nested-components */
import { ContentCopy, OpenInNew } from '@mui/icons-material';
import clsx from 'clsx';
import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useToast } from '../../hooks/useToast';
import { isInternalUrl, Link } from '../Link/Link';

export function allOrigins(url: string) {
	if (isInternalUrl(url)) {
		return url;
	} else {
		// HACK: Using allorigins.win is a temporary solution to avoid mixed content errors in screenshots.
		// We should ideally host our own image proxy, but it will be used so rarely so maybe not worth the effort.
		return `https://api.allorigins.win/raw?url=${encodeURIComponent(url)}`;
	}
}

export function SmartsMarkdown({ children }: { children: string }) {
	const [copyToastState, setCopyToastState] = useToast({ message: 'Copied to clipboard', status: 'info', timeout: 3, showCountdown: false, closeable: false });

	return (
		<Markdown
			remarkPlugins={[remarkGfm]}
			components={{
				h1: ({ children }) => <h1 className="text-lg font-medium my-2">{children}</h1>,
				h2: ({ children }) => <h2 className="text-base font-medium my-2">{children}</h2>,
				h3: ({ children }) => <h3 className="text-sm font-medium my-2">{children}</h3>,
				a: ({ href, children }) => {
					// If link text is surrounded by square brackets, it's a reference and we style it differently
					if (typeof children === 'string' && children.startsWith('[') && children.endsWith(']')) {
						return (
							<a href={href} target={isInternalUrl(href) ? '_self' : '_blank'} className="text-cherry-600 text-xs bg-white rounded-md px-1 py-0.5">
								{children.toString().slice(1, -1)} <OpenInNew className="text-xs -mt-0.5" />
							</a>
						);
					} else {
						if (isInternalUrl(href)) {
							return <Link to={href}>{children}</Link>;
						} else {
							return <Link href={href} target="_blank">{children}</Link>;
						}
					}
				},
				ol: ({ children }) => <ol className="list-decimal list-outside pl-4">{children}</ol>,
				ul: ({ children }) => <ul className="list-disc list-outside pl-4">{children}</ul>,
				li: ({ children }) => <li className="my-2">{children}</li>,
				p: ({ children }) => <p className="my-2 whitespace-pre-line">{children}</p>,
				th: ({ children }) => <th className="border-b border-gray-200 text-left whitespace-nowrap p-2">{children}</th>,
				tr: ({ children }) => <tr className="border-b border-gray-200">{children}</tr>,
				td: ({ children }) => <td className="whitespace-nowrap p-2">{children}</td>,
				table: ({ children }) => <table className="block whitespace-nowrap overflow-x-auto">{children}</table>,
				img: ({ src, alt }) => <a href={src} target="_blank" rel="noreferrer"><img src={src} alt={alt} className="max-w-full h-auto" /></a>,
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				code: ({ children, className, node, ...rest }) => {
					const lang = className?.match(/language-(?<lang>.*)/)?.groups?.lang;
					return lang ? (
						// Code block
						<div className="bg-white rounded-md p-1 my-2">
							<div className="flex flex-col gap-1">
								<div className="flex justify-between items-center pl-2">
									{lang && <span className="text-gray-400 text-xs">{lang}</span>}
									<button
										className="p-1 text-gray-400 hover:text-gray-600 top-0 right-0"
										onClick={() => { navigator.clipboard.writeText(children as string); setCopyToastState({ ...copyToastState, open: true }); }}
									>
										<ContentCopy fontSize="small" />
									</button>
								</div>
								<div className="bg-gray-100/50 rounded-md p-1 overflow-x-auto">
									<code className={clsx('bg-transparent whitespace-pre', className)} {...rest}>{children}</code>
								</div>
							</div>
						</div>
					) : (
						// Inline code
						<code className={className} {...rest}>{children}</code>
					);
				},
			}}
		>
			{children}
		</Markdown>
	);
}
