/* eslint-disable react/no-unstable-nested-components */
import { BugReport, ChatBubbleOutline, Check, ContentCopy, ErrorOutline, ExpandMore, KeyboardArrowDown, MoreVert, Person, Psychology, PushPin, PushPinOutlined, Refresh, Remove, Screenshot, SendRounded, Stop, Translate, WarningAmberOutlined, WbIncandescentOutlined } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem, Tooltip } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';
import clsx from 'clsx';
import { toPng } from 'html-to-image';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RelevantLogo from '../../assets/Relevant_logoicon.png';
import { useToast } from '../../hooks/useToast';
import { useTypewriter } from '../../hooks/useTypewriter';
import { useVisibilityState } from '../../hooks/useVisibilityState';
import { stores } from '../../stores';
import { useAiStore } from '../../stores/AiStore';
import { useReportStore } from '../../stores/ReportStore';
import Report from '../Report';
import { allOrigins, SmartsMarkdown } from './SmartsMarkdown';
import { aiApiCall } from "./utils";

type ChatLogItem = {
	role: 'assistant' | 'user';
	type: string;
	content?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: any;
	attributes?: { [key: string]: string };
	incomplete?: boolean;
	streaming?: boolean;
	time: string;
	usage?: number;
	context?: {
		reportId?: string;
		reportSettings?: object;
		language?: string;
		helpKey?: string;
	}
};

export function AnimatedMarkdown({ content, streaming }: { content: string, streaming?: boolean }) {
	return <SmartsMarkdown>{useTypewriter({ text: content, streaming })}</SmartsMarkdown>;
}

export function Smarts() {
	// const [chatHistoryOpen, setChatHistoryOpen] = useState(true);
	const reportStore = useReportStore();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [language, setLanguage] = useLocalStorage<string>('aiLang', 'english');
	const { isPinned, setIsPinned, isOpen, setIsOpen, pinnedWidth, setPinnedWidth, setSendMessage } = useAiStore();
	const [visible, setVisible, shouldRender] = useVisibilityState(isOpen, 300);
	const [copyToastState, setCopyToastState] = useToast({ message: 'Copied to clipboard', status: 'info', timeout: 3, showCountdown: false, closeable: false });
	const [, setScreenshotToastState] = useToast({});
	// const { ref: chatLogRef, isBottom } = useScrollState();
	const chatLogRef = useRef<HTMLDivElement>(null);
	const [isAtBottom, setIsAtBottom] = useState(false);
	const [autoScroll, setAutoScroll] = useLocalStorage<boolean>('aiAutoScroll', true);
	const [debug, setDebug] = useLocalStorage<boolean>('aiDebug', false);
	const input = useRef<HTMLInputElement>(null);

	const queryClient = useQueryClient();
	const queryKey = useMemo(() => ['ai', 'history', { debug }], [debug]);

	const chatLogQuery = useQuery<ChatLogItem[]>({
		queryKey,
		queryFn: async () => {
			return await aiApiCall('aiHistory', { context: { language, localTime: new Date(), debug } });
		},
		initialData: [{
			role: 'assistant',
			type: 'reply',
			content: null,
			time: Date.now().toString(),
		}],
	});

	const chatLogAdd = useMutation({
		mutationFn: async (logItem: ChatLogItem) => {
			await aiApiCall('aiChat', {
				message: logItem.content,
				time: logItem.time,
				context: logItem.context,
				msgChannel: {
					// Handle stream start
					streamStart: () => {
						// No action needed - we already handle this in onMutate
					},

					// Handle individual messages
					message: (msg) => {
						// console.log('message', msg);
						queryClient.setQueryData(queryKey, (old: ChatLogItem[] = []) => [
							...old.filter((m) => m.content !== null), // Filter out placeholder on first message
							msg,
						]);
					},

					delta: (delta: ChatLogItem) => {
						queryClient.setQueryData(queryKey, (old: ChatLogItem[] = []) => {
							return old.reduce((acc, m) => {
								if (m.time === delta.time) {
									acc.push({ ...m, ...delta, content: delta.incomplete ? m.content + delta.content : delta.content });
								} else {
									acc.push(m);
								}
								return acc;
							}, []);
						});

						if (!delta.incomplete) {
							if (delta.type === 'reply') {
								if (delta.attributes?.['lang']) {
									setLanguage(delta.attributes?.['lang']);
								}
							}
						}
					},

					// Handle stream end
					streamEnd: ({ usage }) => {
						// Update the first assistant message with usage data
						queryClient.setQueryData(queryKey, (old: ChatLogItem[] = []) => {
							const updated = [...old];
							const lastUserIndex = updated.findLastIndex((m) => m.type === 'request' && m.role === 'user');
							if (lastUserIndex > -1) {
								updated[lastUserIndex + 1].usage = usage;
							}
							return updated;
						});
					},

					// Handle channel closed
					onChannelClosed: ({ error }) => {
						if (error) {
							// eslint-disable-next-line no-console
							console.error('Channel closed with error:', error);
						}
					},
				},
			});

			// TODO: Live report updates
			// if (response?.report) {
			// 	setCurrentReportSettings({
			// 		...currentReportSettings,
			// 		...response.report,
			// 	});
			// }
		},
		onMutate: async (logItem) => {
			await queryClient.cancelQueries({ queryKey });
			const previous = queryClient.getQueryData(queryKey);
			// Optimistically add the new item, and a loading indicator
			if (!logItem.context?.helpKey) { // Queries from SmartHelp shouldn't be visible in the chat log
				queryClient.setQueryData(queryKey, (old: ChatLogItem[]) => [
					...old,
					logItem,
					{ type: 'reply', role: 'assistant', content: null, incomplete: true, time: Date.now().toString() },
				]);
			}
			return { previous };
		},
		onError: (err, newMessage, { previous }) => {
			// eslint-disable-next-line no-console
			console.error(err);
			queryClient.setQueryData(queryKey, previous);
			// Append frontend error message
			queryClient.setQueryData(queryKey, (old: ChatLogItem[] = []) => [
				...old,
				{
					role: 'assistant',
					type: 'toast',
					data: { status: 'warning', message: 'Temporary outage. Please try again.' },
					time: Date.now().toString(),
				},
			]);
		},
	});

	const chatLogClear = useMutation({
		mutationFn: async () => {
			await sendStop();
			await aiApiCall('aiClearHistory', { context: { language, localTime: new Date(), debug } });
			return [];
		},
		onMutate: () => {
			queryClient.setQueryData(queryKey, [
				{ type: 'reply', role: 'assistant', content: null, incomplete: true, time: Date.now().toString() },
			]);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey });
		},
	});

	const handleSetVisible = useCallback((state: boolean) => {
		setVisible(state);
		setIsOpen(state);
	}, [setIsOpen, setVisible]);
	useEffect(() => {
		if (visible) {
			input.current?.focus();
		}
	}, [visible, input]);

	const sendMessage = useCallback(async (message: string, context: object = {}) => {
		if (!visible) {
			handleSetVisible(true);
		}
		setAutoScroll(true);
		const mergedContext = {
			reportId: reportStore.currentReport?.id,
			reportSettings: reportStore.currentReportSettings ? Report.getCurrentPropertiesFromState(reportStore.currentReportSettings, false) : undefined,
			language,
			localTime: new Date(),
			isPinned,
			debug,
			...context,
		};
		chatLogAdd.mutate({ type: 'request', role: 'user', content: message, time: Date.now().toString(), context: mergedContext });
	}, [chatLogAdd, reportStore, language, isPinned, debug, visible, handleSetVisible, setAutoScroll]);
	// Register the sendMessage function for outside use
	// HACK: This is really ugly but a quick workaround for keeping the sendMessage callback up to date inside aiStore :/
	const sendMessageRef = useRef<(message: string, context?: object) => Promise<void>>();
	sendMessageRef.current = sendMessage;
	useEffect(() => {
		setSendMessage(sendMessageRef);
		return () => setSendMessage(null);
	}, [setSendMessage]);

	const sendStop = useCallback(async () => {
		if (!chatLogAdd.isPending) {
			return;
		}
		const lastUserMessage = chatLogQuery.data.filter((item) => item.type === 'request' && item.role === 'user').at(-1);
		if (lastUserMessage) {
			await aiApiCall('aiStop', { key: lastUserMessage.time });
		}
	}, [chatLogQuery.data, chatLogAdd.isPending]);

	// Auto-scroll on data change
	useEffect(() => {
		const chatLog = chatLogRef.current;
		if (!chatLog || !autoScroll) {
			return () => {};
		}
		const resizeObserver = new ResizeObserver(() => {
			chatLog.scrollTop = chatLog.scrollHeight;
		});
		resizeObserver.observe(chatLog);
		chatLog.scrollTop = chatLog.scrollHeight;
		return () => {
			resizeObserver.disconnect();
		};
	});

	// Toggle auto-scroll on when user scrolls to the bottom, and off on wheel up
	useEffect(() => {
		const chatLog = chatLogRef.current;
		if (!chatLog) {
			return () => {};
		}
		const scrollListener = () => {
			const bottom = (chatLog.scrollHeight - chatLog.clientHeight) - chatLog.scrollTop < 1;
			// eslint-disable-next-line no-console
			setIsAtBottom(bottom);
			if (bottom && !autoScroll) {
				setAutoScroll(true);
			}
		};
		const wheelListener = (ev: WheelEvent) => {
			if (ev.deltaY < 0 && autoScroll) {
				setAutoScroll(false);
			}
		};
		chatLog.addEventListener('scroll', scrollListener);
		chatLog.addEventListener('wheel', wheelListener);
		return () => {
			chatLog.removeEventListener('scroll', scrollListener);
			chatLog.removeEventListener('wheel', wheelListener);
		};
	});

	const handleSend = useCallback(async () => {
		if (!chatLogAdd.isPending) {
			const lastItem = chatLogQuery.data[chatLogQuery.data.length - 1];
			const suggestion = lastItem?.type === 'suggest' && lastItem.data?.[0];
			const message = input.current?.value || suggestion;
			if (message) {
				sendMessage(message);
				input.current.value = '';
			}
		} else {
			sendStop();
		}
	}, [chatLogAdd, chatLogQuery.data, sendMessage, sendStop]);

	async function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter') {
			handleSend();
		}
	}

	async function screenshot() {
		if (chatLogRef.current) {
			setScreenshotToastState({ message: 'Saving screenshot...', status: 'info', icon: <CircularProgress size="1.5rem" />, closeable: false, open: true });
			try {
				const dataUrl = await toPng(chatLogRef.current, {
					height: chatLogRef.current.scrollHeight,
					// No idea why this is needed, but it will fail with a cryptic error otherwise
					// https://github.com/bubkoo/html-to-image/issues/467#issuecomment-2368870952
					fetchRequestInit: {
						method: 'GET',
						cache: 'no-cache',
					},
					filter: (node) => {
						// Send img tags through allOrigins to avoid mixed content errors
						if (node.tagName === 'IMG') {
							const src = node.getAttribute('src');
							node.setAttribute('src', allOrigins(src));
						}
						return true;
					},
				});
				const a = document.createElement('a');
				a.href = dataUrl;
				a.download = `chat_${stores.identity.userId()}_${new Date().toISOString().replace(/[:.]/g, '-')}.png`;
				a.click();
			} catch (e) {
				setScreenshotToastState({ message: 'Screenshot failed', status: 'error', timeout: 3, open: true });
				throw e;
			}
			setScreenshotToastState({});
		}
	}

	const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleLanguageClose = () => {
		setAnchorEl(null);
	};

	// DEBUG: print data every time it changes
	useEffect(() => {
		if (debug) {
			// eslint-disable-next-line no-console
			console.log('chatLogQuery.data', chatLogQuery.data);
		}
	}, [chatLogQuery.data, debug]);

	// Resize in pinned mode
	const aiPanelRef = useRef<HTMLDivElement>(null);
	const handleMouseDown = useCallback((e: React.MouseEvent) => {
		if (e.button !== 0) {
			return;
		}
		e.preventDefault();
		const startX = e.clientX;
		const startWidth = pinnedWidth;

		if (!aiPanelRef.current) {
			return;
		}

		const mainPlaceholder = document.getElementById('main-ai-placeholder');
		// Disable transitions while dragging
		aiPanelRef.current!.style.transitionProperty = 'none';
		if (mainPlaceholder) {
			mainPlaceholder.style.transitionProperty = 'none';
		}

		const handle = e.target as HTMLElement;
		handle.style.setProperty('--ai-splitter-opacity', '1');
		// console.log('handle opacity', handle.style.opacity);

		let rafId: number | null = null;
		const handleMouseMove = (moveEvent: MouseEvent) => {
			if (rafId) {
				cancelAnimationFrame(rafId);
			}

			rafId = requestAnimationFrame(() => {
				const delta = startX - moveEvent.clientX;
				const newWidth = Math.max(300, Math.min(1500, startWidth + delta));
				// Update both the AI panel and the main placeholder
				aiPanelRef.current.style.setProperty('--ai-width', `${newWidth}px`);
				mainPlaceholder.style.setProperty('--ai-width', `${newWidth}px`);
			});
		};

		const handleMouseUp = () => {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
			if (rafId) {
				cancelAnimationFrame(rafId);
			}

			const currentWidth = parseInt(getComputedStyle(aiPanelRef.current!)
				.getPropertyValue('--ai-width'), 10);
			setPinnedWidth(currentWidth);

			// Re-enable transitions
			aiPanelRef.current!.style.transitionProperty = '';
			if (mainPlaceholder) {
				mainPlaceholder.style.transitionProperty = '';
			}

			handle.style.setProperty('--ai-splitter-opacity', '0');
		};

		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
	}, [pinnedWidth, setPinnedWidth]);

	const handleSetPinned = useCallback((pinned: boolean) => {
		setIsPinned(pinned);
		setAutoScroll(false);
	}, [setIsPinned, setAutoScroll]);

	// HACK: Make wheel events "pass through" the "Scroll to bottom" button down to the chat log,
	// since otherwise the scrolling gets stuck if your mouse is over where the button appears
	// when you start scrolling up.
	const scrollToBottomButtonRef = useRef<HTMLButtonElement>(null);
	useEffect(() => {
		const button = scrollToBottomButtonRef.current;
		if (!button) {
			return () => {};
		}
		const wheelHandler = (e: WheelEvent) => {
			e.stopPropagation();
			e.preventDefault();
			if (chatLogRef.current) {
				chatLogRef.current.scrollTop += e.deltaY;
			}
		};
		button.addEventListener('wheel', wheelHandler, { passive: false });
		return () => button.removeEventListener('wheel', wheelHandler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleRegenerate = useCallback(() => {
		const index = chatLogQuery.data.findLastIndex((m) => m.role === 'user');
		const lastUserMessage = chatLogQuery.data[index];
		if (lastUserMessage?.content) {
			// Erase everything from the user's last message onwards and send it again
			queryClient.setQueryData(queryKey, (old: ChatLogItem[]) => old.slice(0, index));
			sendMessage(lastUserMessage.content, { regenerate: true });
		}
	}, [chatLogQuery.data, sendMessage, queryClient, queryKey]);

	const handleDebug = useCallback(() => {
		if (!stores.identity.isSuperAdministrator()) {
			return;
		}
		if (chatLogAdd.isPending || chatLogQuery.isPending) {
			return;
		}
		setDebug(!debug);
		chatLogQuery.refetch();
	}, [debug, setDebug, chatLogAdd.isPending, chatLogQuery]);

	// Add new state for more menu
	const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);

	// Add handler functions
	const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
		setMoreAnchorEl(event.currentTarget);
	};

	const handleMoreClose = () => {
		setMoreAnchorEl(null);
	};

	return (
		<>
			{/* Show/hide button */}
			<div
				className={clsx(
					'fixed bottom-0 right-0 z-[1100] transition-all duration-300 ease-in-out w-[48px] h-[48px] mr-4 mb-4 overflow-visible',
					'hover:w-[175px] rounded-full group',
					'bg-gradient-to-r from-[#040E20] to-blue-400',
					// 'bg-red-600',
					visible ? 'opacity-0' : 'opacity-100',
				)}
				onClick={() => handleSetVisible(true)}
				onKeyDown={(e) => e.key === 'Enter' && handleSetVisible(true)}
				tabIndex={0}
				role="button"
				inert={visible ? '' : undefined}
			>
				<div className="absolute z-[1] w-[48px] h-[48px] rounded-full bg-red-500 flex items-center justify-center cursor-pointer flex-shrink-0">
					<img src={RelevantLogo} alt="Relevant Logo" className="w-8 h-8" />
				</div>
				<div className="w-full h-full p-[2px]">
					<div className="bg-white rounded-full flex items-center w-full h-full overflow-hidden">
						<div className="flex items-center -translate-x-full group-hover:translate-x-0 transition-all duration-300 ease-in-out ml-[58px]">
							<span className="font-semibold text-lg flex-shrink-0 bg-gradient-to-r from-blue-950 to-blue-400 text-transparent bg-clip-text">Relevant AI</span>
						</div>
					</div>
				</div>
			</div>

			{/* Chat container */}
			{shouldRender && (
				<div
					className={clsx(
						'fixed left-0 bottom-0 right-0 z-[1101] flex flex-col items-end pointer-events-none',
						'top-16', // Compensate for the top bar
					)}
				>
					<div className="flex-1" />
					<div
						className={clsx(
							'flex-initial border-gray-200 border box-border',
							'transition-all duration-300 ease-in-out',
							'flex flex-col bg-white relative pointer-events-auto',
							isPinned ? 'h-full' : 'h-[700px] m-4 rounded-lg',
							visible
								? 'opacity-100 shadow-lg'
								: 'opacity-0 translate-y-6',
						)}
						style={{
							width: isPinned ? `var(--ai-width)` : '600px',
							['--ai-width' as string]: `${pinnedWidth}px`,
						}}
						ref={aiPanelRef}
						inert={!visible ? '' : undefined}
					>
						{/* Splitter handle */}
						{isPinned && (
							// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
							<div
								className={clsx(
									'absolute z-[1102] left-[-5px] top-0 bottom-0 w-[10px] cursor-col-resize',
									// Cool gradient effect that shows the handle
									'before:absolute before:inset-0',
									'before:opacity-[--ai-splitter-opacity] hover:before:opacity-100',
									'before:transition-opacity before:duration-200',
									'before:bg-gradient-to-r before:from-blue-500/20 before:via-blue-500/10 before:to-transparent',
									'before:backdrop-blur-sm',
								)}
								style={{ '--ai-splitter-opacity': '0' } as React.CSSProperties}
								role="separator"
								onMouseDown={handleMouseDown}
							/>
						)}

						{/* Header */}
						<div className="flex items-center justify-between px-4 py-3 border-b border-gray-200">
							<div className="flex items-center gap-2 flex-shrink overflow-hidden whitespace-nowrap select-none">
								<img src={RelevantLogo} alt="Relevant Logo" className="w-6 h-6" />
								<span className="font-semibold text-lg flex-shrink-0 bg-gradient-to-r from-[#040E20] to-blue-400 text-transparent bg-clip-text">Relevant AI</span>
								{debug && <span className="text-xs text-gray-400"><BugReport fontSize="small" /></span>}
							</div>
							<div className="flex items-center gap-2 flex-shrink-0">
								<button
									onClick={() => chatLogClear.mutate()}
									className="flex items-center gap-2 px-3 py-1 rounded-md bg-blue-50 text-blue-600 hover:bg-blue-100 border border-blue-200"
								>
									<ChatBubbleOutline fontSize="small" />
									<span className="text-sm">New Chat</span>
								</button>
								<div className="ml-2 w-px h-4 bg-gray-200" />
								{/* ... menu */}
								<div className="flex items-center gap-2">
									<Tooltip title="More options">
										<button
											onClick={handleMoreClick}
											className="text-gray-400 hover:text-gray-600 p-1"
										>
											<MoreVert fontSize="small" />
										</button>
									</Tooltip>
									<Menu
										anchorEl={moreAnchorEl}
										open={Boolean(moreAnchorEl)}
										onClose={handleMoreClose}
										disableScrollLock
									>
										<MenuItem onClick={() => { handleMoreClose(); screenshot(); }}>
											<Screenshot fontSize="small" className="mr-2" />
											Save Screenshot
										</MenuItem>
										{/* Debug mode */}
										{stores.identity.isSuperAdministrator() && (
											<MenuItem onClick={handleDebug}>
												<BugReport fontSize="small" className="mr-2" />
												Debug Mode
											</MenuItem>
										)}
									</Menu>
								</div>
								<Tooltip title={isPinned ? 'Unpin' : 'Pin to side'}>
									<button onClick={() => handleSetPinned(!isPinned)} className="text-gray-400 hover:text-gray-600 p-1">
										{isPinned ? <PushPin fontSize="small" /> : <PushPinOutlined fontSize="small" className="-rotate-45" />}
									</button>
								</Tooltip>
								<Tooltip title="Minimize">
									<button onClick={() => handleSetVisible(false)} className="text-gray-400 hover:text-gray-600 p-1">
										<Remove fontSize="small" />
									</button>
								</Tooltip>
							</div>

						</div>

						{/* Chat Log */}
						<div className="flex-grow relative overflow-hidden">
							{/* TODO: History sidebar */}
							{/* <div className={clsx(
								'absolute inset-0 left-auto min-w-[300px] bg-white/80 backdrop-blur-lg border-l z-[2] p-4 flex flex-col gap-4 transition-all duration-300 ease-in-out',
								chatHistoryOpen ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0 pointer-events-none',
							)}>
								<input
									type="text"
									className="w-full border border-gray-300 rounded-md px-2 py-1 text-sm focus:border-black focus:outline-none placeholder-gray-400 focus:placeholder-transparent bg-red"
									placeholder="Search chats..."
								/>
								<div className="flex flex-col gap-2 overflow-y-auto">
									<span className="text-xs text-gray-500">5m ago</span>
									<span className="text-sm text-gray-800"><ChatBubbleOutline fontSize="small" /> What is the capital of France?</span>
									<span className="text-sm text-gray-800"><ChatBubbleOutline fontSize="small" /> What is the capital of France?</span>
									<span className="text-xs text-gray-500">2d ago</span>
									<span className="text-sm text-gray-800"><ChatBubbleOutline fontSize="small" /> What is the capital of France?</span>
								</div>
							</div> */}

							{/* Fade-effect when not scrolled to bottom */}
							<div className={clsx(
								'pointer-events-none absolute bottom-0 left-0 right-0 z-[1] h-20 bg-gradient-to-t from-white to-transparent transition-opacity duration-100 ease-in-out',
								(isAtBottom || autoScroll) && 'opacity-0',
							)} />
							{/* "Scroll to bottom" button */}
							<div className={clsx(
								'pointer-events-none absolute bottom-0 left-0 right-0 z-[1] flex items-center justify-center transition-all duration-100 ease-in-out',
								(isAtBottom || autoScroll) && 'translate-y-full opacity-0',
							)}>
								<button
									className="pointer-events-auto mb-2 rounded-md border border-gray-300 bg-white px-2 py-0.5 align-middle text-sm text-gray-500 hover:text-gray-600"
									onClick={() => setAutoScroll(true)}
									ref={scrollToBottomButtonRef}
								>
									<KeyboardArrowDown fontSize="small" />
									<span className="mx-2">Scroll to bottom</span>
									<KeyboardArrowDown fontSize="small" />
								</button>
							</div>

							{/* Scrollable */}
							<div ref={chatLogRef} className="absolute inset-0 overflow-y-auto p-4 bg-white">
								<div className="flex flex-col gap-2">
									{chatLogQuery.data
										.map((item, index, data) => {
											const isNewRole = index === 0 || data[index - 1].role !== item.role;
											const isLatestMessage = index === data.length - 1;
											const isLatestReply = item.type === 'reply' && !data.slice(index + 1).find((m) => m.type === 'reply');

											return (
												<React.Fragment key={item.time}>
													{/* // key={item.time}
													// className={clsx('flex flex-col gap-2', isNewRole && index !== 0 && 'mt-2')}
													// data-message-time={item.time}
													// data-item={item.type} */}
													{/* Header */}
													{isNewRole && false && (
														<div
															data-time={item.time}
															data-type={item.type}
															className={clsx(
																'text-sm flex items-center group',
																index !== 0 && 'mt-2',
																item.role === 'assistant' ? 'justify-start' : 'justify-end',
															)}
														>
															<div className="flex items-center gap-2">
																{item.role === 'assistant' ? (
																	<img src={RelevantLogo} alt="Assistant" className="w-5 h-5" />
																) : (
																	<div className="w-5 h-5 bg-gray-300 rounded-full flex items-center justify-center">
																		<Person fontSize="small" className="text-white" />
																	</div>
																)}
																<span className="font-semibold">{item.role === 'assistant' ? 'Assistant' : 'You'}</span>
																<span className="text-gray-400 text-xs whitespace-nowrap">{moment(Number(item.time.split('-')[0])).format('h:mm A')}</span>
																{debug && item.usage && (
																	<span className="text-gray-400 text-xs">
																		${item.usage?.toFixed(3)}
																	</span>
																)}
															</div>
														</div>
													)}
													{isNewRole && index !== 0 && (
														<div className="mt-1" />
													)}

													{/* Debug */}
													{(debug && !['request', 'reply'].includes(item.type)) && (
														<div
															data-time={item.time}
															data-type={item.type}
															className={clsx('flex', item.role === 'assistant' ? 'justify-start' : 'justify-end', 'w-full')}
														>
															<div className={clsx(
																'rounded-lg px-3 max-w-full relative group animate-fade-in-left',
																item.role === 'assistant' ? 'bg-gray-100 text-gray-800' : 'bg-blue-600 text-white',
															)}>
																<div>
																	<div className="flex items-center gap-2 my-2">
																		<BugReport fontSize="small" className="text-gray-500" />
																		<pre className="text-gray-600 whitespace-pre-wrap text-sm">
																			{item.type}
																		</pre>
																	</div>
																	{item.attributes?.reason && (
																		<code className="text-gray-400 text-xs my-2 block">
																			{item.attributes?.reason}
																		</code>
																	)}
																	{item.content && (
																		<code className="text-gray-400 text-xs my-2 block whitespace-pre-wrap">
																			{item.data ? JSON.stringify(item.data, null, 2) : item.content}
																		</code>
																	)}
																</div>
															</div>
														</div>
													)}

													{/* Toast */}
													{item.type === 'toast' && (
														<div
															data-time={item.time}
															data-type={item.type}
															className={clsx(
																'flex items-center gap-3 rounded-md px-3 p-2 font-normal text-sm animate-fade-in-left group relative',
																{
																	'text-success-blue-80 bg-success-blue-5 border border-success-blue-60': item.data?.status === 'info' || !item.data?.status,
																	'text-success-green-80 bg-success-green-5 border border-success-green-60': item.data?.status === 'success',
																	'text-warning-yellow-80 bg-warning-yellow-5 border border-warning-yellow-70': item.data?.status === 'warning',
																	'text-failure-red-80 bg-failure-red-5 border border-failure-red-60': item.data?.status === 'error',
																},
															)}
														>
															{item.data?.status === 'success' && <Check />}
															{item.data?.status === 'warning' && <WarningAmberOutlined />}
															{item.data?.status === 'error' && <ErrorOutline />}
															{(item.data?.status === 'info' || !item.data?.status) && <WbIncandescentOutlined className="rotate-180" />}
															<p className="whitespace-pre-line">{item.data?.message}</p>

															{/* Regenerate button on error toasts */}
															{item.data?.status === 'error' && (
																<div className={clsx(
																	'absolute -bottom-3 -right-3 bg-white rounded-lg shadow-md border border-gray-200',
																	'opacity-0 group-hover:opacity-100 transition-opacity',
																	'flex items-center gap-0.5',
																)}>
																	<Tooltip title="Retry">
																		<button
																			onClick={handleRegenerate}
																			className="p-1 text-gray-500 hover:text-gray-700 hover:bg-gray-50 rounded-r-lg"
																		>
																			<Refresh fontSize="small" />
																			<span className="mx-1">Retry</span>
																		</button>
																	</Tooltip>
																</div>
															)}
														</div>
													)}

													{/* Content */}
													{(item.type === 'request' || item.type === 'reply') && item.content && (
														<div
															data-time={item.time}
															data-type={item.type}
															className={clsx(
																'flex relative',
																item.role === 'assistant' ? 'justify-start' : 'justify-end',
																'w-full',
															)}
														>
															<div className={clsx(
																'rounded-lg px-3 max-w-full relative group',
																item.role === 'assistant' ? 'bg-gray-100 text-gray-800 animate-fade-in-left' : 'bg-blue-600 text-white animate-fade-in-right',
															)}>
																{/* Chat Bubble Tail */}
																<div
																	className={clsx(
																		'absolute bottom-[0px] w-[16px] h-[16px]',
																		item.role === 'assistant'
																			? 'left-0 -translate-x-[8px] -scale-x-100 text-gray-100'
																			: 'right-0 translate-x-[8px] text-blue-600',
																	)}
																>
																	<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
																		<path fill="currentColor" d="m50-61.9c0 0-12.2 142.8 50 161.9-107.9 0-100 0-100 0l-43.7-103.5z" />
																	</svg>
																</div>

																{/* Content */}
																{item.role === 'assistant' ? (
																	<AnimatedMarkdown
																		content={item.content}
																		streaming={item.streaming || item.incomplete}
																	/>
																) : (
																	<SmartsMarkdown>{item.content}</SmartsMarkdown>
																)}

																{/* Message actions bubble */}
																<div className={clsx(
																	'absolute -bottom-1 -right-1 bg-white rounded-lg shadow-md border border-gray-200 z-[1]',
																	'opacity-0 group-hover:opacity-100 transition-opacity',
																	'flex items-center gap-0.5',
																)}>
																	{item.role === 'assistant' && item.content && (
																		<>
																			<Tooltip title="Copy">
																				<button
																					onClick={() => {
																						navigator.clipboard.writeText(item.content);
																						setCopyToastState({ ...copyToastState, open: true });
																					}}
																					className="p-1 text-gray-500 hover:text-gray-700 hover:bg-gray-50 rounded-l-lg"
																				>
																					<ContentCopy fontSize="small" />
																				</button>
																			</Tooltip>
																			{isLatestReply && (
																				<>
																					<div className="w-px h-4 bg-gray-200" />
																					<Tooltip title="Regenerate">
																						<button
																							onClick={handleRegenerate}
																							className="p-1 text-gray-500 hover:text-gray-700 hover:bg-gray-50 rounded-r-lg"
																						>
																							<Refresh fontSize="small" />
																						</button>
																					</Tooltip>
																				</>
																			)}
																		</>
																	)}
																</div>
															</div>
														</div>
													)}

													{/* Sources */}
													{item.type === 'sources' && Array.isArray(item.data) && item.data.length > 0 && (
														<div
															data-time={item.time}
															data-type={item.type}
															className="bg-transparent flex flex-col gap-2 w-full overflow-hidden"
														>
															<div className="flex flex-col gap-2">
																{item.data.map((source) => (
																	<a
																		key={source.url}
																		href={source.url}
																		target="_blank"
																		rel="noreferrer"
																		className="flex flex-row gap-2 rounded-lg px-4 py-2 border border-gray-200 bg-white hover:bg-gray-50 transition-colors duration-200 overflow-hidden animate-fade-in-left"
																	>
																		<img src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${encodeURIComponent(source.url)}&size=16`} alt="Favicon" className="w-4 h-4 mt-1 shrink-0" />
																		{/* <LinkRounded className="text-gray-500" /> */}
																		<div className="flex flex-col min-w-0">
																			<span className="text-gray-900 truncate">{source.title ?? source.url}</span>
																			<span className="text-xs text-cherry-600 truncate">{source.url}</span>
																		</div>
																	</a>
																))}
															</div>
														</div>
													)}

													{/* Reasoning */}
													{(item.attributes?.reason) && (
														<div
															data-time={item.time}
															data-type={item.type}
															className={clsx('flex', item.role === 'assistant' ? 'justify-start' : 'justify-end', 'w-full')}
														>
															<div className={clsx(
																'rounded-lg px-3 max-w-full relative group animate-fade-in-left',
																item.role === 'assistant' ? 'bg-gray-100 text-gray-800' : 'bg-blue-600 text-white',
															)}>
																<div>
																	<div className="flex items-center gap-2 my-2">
																		<Psychology fontSize="small" className="text-gray-500" />
																		<span className="text-gray-600 italic">
																			{item.attributes?.reason}
																		</span>
																	</div>
																</div>
															</div>
														</div>
													)}

													{/* Loading indicator */}
													{(
														// Show loading indicator only after latest message, as long as request is pending,
														// unless message is currently streaming and therefore doesn't need a redundant loading indicator.
														isLatestMessage
														&& (chatLogAdd.isPending || chatLogClear.isPending || chatLogQuery.isFetching)
														&& (!item.streaming) // || !item.incomplete)
													) && (
														<div
															data-time={item.time}
															data-type={item.type}
															className={clsx('flex', item.role === 'assistant' ? 'justify-start' : 'justify-end', 'w-full')}
														>
															<div className={clsx(
																'rounded-lg px-3 py-2 max-w-full animate-fade-in-left flex items-center select-none',
																item.role === 'assistant' ? 'bg-gray-100 text-gray-800' : 'bg-blue-600 text-white',
															)}>
																<span className="inline-flex space-x-1">
																	<div className="w-2 h-2 bg-current rounded-full animate-typing-1" />
																	<div className="w-2 h-2 bg-current rounded-full animate-typing-2" />
																	<div className="w-2 h-2 bg-current rounded-full animate-typing-3" />
																</span>
																{/* HACK: force line height to match other chat bubbles */}
																<span className="invisible w-0 overflow-hidden">🦊</span>
															</div>
														</div>
													)}
												</React.Fragment>
											);
										})}
								</div>

								{/* Action Bar */}
								{(() => {
									const lastMessage = chatLogQuery.data[chatLogQuery.data.length - 1];
									const suggestions = lastMessage.type === 'suggest' ? lastMessage : null;
									return (suggestions) && (
										<div className="flex justify-between mt-2 text-sm items-start animate-fade-in">
											<div className="flex flex-row gap-2 flex-wrap">
												{Array.isArray(suggestions.data) && suggestions.data?.map((suggestion, index) => (
													<button
														// eslint-disable-next-line react/no-array-index-key
														key={index}
														className="text-left text-xs text-gray-700 hover:bg-gray-100 border border-gray-300 rounded-md px-2 py-0.5 transition-colors flex-shrink-0 whitespace-nowrap"
														onClick={() => sendMessage(suggestion)}
													>
														{suggestion}
													</button>
												))}
											</div>
											<div className="flex items-center gap-2">
												{/* <button
													onClick={() => {}}
													className="text-gray-500 hover:text-gray-700 flex items-center gap-1"
												>
													<Refresh fontSize="small" />
													Regenerate
												</button> */}
												<button
													onClick={handleLanguageClick}
													className="text-gray-500 hover:text-gray-700 flex items-center gap-1"
												>
													<Translate fontSize="small" />
													Translate
													<ExpandMore fontSize="small" />
												</button>
												<Menu
													anchorEl={anchorEl}
													open={Boolean(anchorEl)}
													onClose={handleLanguageClose}
												>
													<MenuItem onClick={() => { handleLanguageClose(); sendMessage('In English, please.'); setLanguage('eng'); }}>
														<span className="mr-2">🇺🇸</span> English
													</MenuItem>
													<MenuItem onClick={() => { handleLanguageClose(); sendMessage('Suomeksi, kiitos.'); setLanguage('fin'); }}>
														<span className="mr-2">🇫🇮</span> Suomeksi
													</MenuItem>
													<MenuItem onClick={() => { handleLanguageClose(); sendMessage('På svenska, tack.'); setLanguage('swe'); }}>
														<span className="mr-2">🇸🇪</span> Svenska
													</MenuItem>
													<MenuItem onClick={() => { handleLanguageClose(); sendMessage('Auf Deutsch, bitte.'); setLanguage('deu'); }}>
														<span className="mr-2">🇩🇪</span> Deutsch
													</MenuItem>
													<MenuItem onClick={() => { handleLanguageClose(); sendMessage(`En français, s'il vous plait.`); setLanguage('fra'); }}>
														<span className="mr-2">🇫🇷</span> Français
													</MenuItem>
													<MenuItem onClick={() => { handleLanguageClose(); sendMessage('En español, por favor.'); setLanguage('spa'); }}>
														<span className="mr-2">🇪🇸</span> Español
													</MenuItem>
													<MenuItem onClick={() => { handleLanguageClose(); sendMessage('In italiano, per favore.'); setLanguage('ita'); }}>
														<span className="mr-2">🇮🇹</span> Italiano
													</MenuItem>
													<MenuItem onClick={() => { handleLanguageClose(); sendMessage('Українською, будь ласка.'); setLanguage('ukr'); }}>
														<span className="mr-2">🇺🇦</span> Українська
													</MenuItem>
													<MenuItem onClick={() => { handleLanguageClose(); sendMessage('Which languages do you support?'); }}>
														Other...
													</MenuItem>
												</Menu>
											</div>
										</div>
									);
								})()}
							</div>
						</div>

						{/* Input */}
						<div className="p-3 border-t border-gray-200">
							<div className="relative">
								<input
									type="text"
									className="w-full pr-10 text-gray-700 border border-gray-300 rounded-md px-3 py-2 focus:border-black focus:outline-none placeholder-gray-400 focus:placeholder-transparent bg-red"
									onKeyDown={handleKeyDown}
									ref={input}
									placeholder={(chatLogQuery.data[chatLogQuery.data.length - 1]?.type === 'suggest' && chatLogQuery.data[chatLogQuery.data.length - 1].data?.[0]) || ''}
								/>
								<button
									type="button"
									onClick={handleSend}
									className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
								>
									{chatLogAdd.isPending ? (
										<div className="relative animate-fade-in">
											<div className="absolute inset-0 animate-spin">
												<div className="w-full h-full rounded-full border-2 border-gray-200 border-t-blue-500" />
											</div>
											<Stop className="text-cherry-500 p-0.5" />
										</div>
									) : (
										<SendRounded className="text-blue-500 hover:text-blue-600 animate-fade-in" />
									)}
								</button>
							</div>
						</div>

						{/* Note */}
						<div className="px-4 pb-3 text-xs text-gray-500">
							<span className="font-semibold">Note:</span> AI responses may not always be accurate. Please double check any information.
						</div>
					</div>
				</div>
			)}
		</>
	);
}
