/** A single dimension in report-data used when updating HBA-data from adserver API data. */
class GranularDimension {
	/**
	 * @param {Object} settings - Settings for dimension
	 * @param {string} settings.unitName - Field-name in data returned by RtImporter.importHourlyDataSpecificInternal()
	 * @param {string} settings.hbDim - Name of HBA dimension
	 * @param {string} settings.desc - Description
	 * @param {boolean} settings.checkStringIncluded - If true, API-field must be ones of 'values' (see below)
	 * @param {string[]} settings.values - Allowed values for API-field when 'checkStringIncluded' is used.
	 */
	constructor(settings) {
		Object.assign(this, {
			...settings,
		});
	}

	/** Convert API data value to corresponding values used in HBA reports (or null if not possible) */
	convertToValidUnitValue(val) {
		const { values, checkStringIncluded } = this;
		if (val === null || val === undefined) {
			return null;
		}
		if (values) {
			return values.find((v) => (checkStringIncluded ? val.includes(v) : v === val)) || null;
		}
		return val;
	}
}

module.exports = GranularDimension;
