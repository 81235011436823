import PropTypes from 'prop-types';
import React, {
	useEffect, useState, useRef, useLayoutEffect,
} from 'react';
import _ from 'lodash';
import {
	Box,
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import BarChart from '../BarChart';
import LineChart from '../LineChart';
import { getColor } from './utils';
import { createOptions } from './chartJsUtils';

export default function ReportChart({
	data,
	currency,
	options: extraOptions,
	showLegend,
	type,
	stacked,
	description,
	barChartType,
	showEventLines,
	events,
	levels,
	shortRange,
	groupBy,
}) {
	const ref = useRef(null);
	const [maxTicks, setMaxTicks] = useState(60);

	const setSize = () => {
		const width = ref?.current?.clientWidth;
		const computedTicks = width > 700 ? 60 : Math.min(Math.floor(width / 30), 60);
		setMaxTicks(computedTicks);
	};

	useLayoutEffect(() => {
		setSize();
	});

	useEffect(() => {
		const resizeObserver = new ResizeObserver(() => {
			setSize();
		});

		if (ref.current?.parentElement) {
			resizeObserver.observe(ref.current.parentElement);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const tickCustomizeDates = (ticks) => {
		const hasMinute = shortRange.ms % (3600 * 1000);
		const ranges = [
			{ minute: 10 },
			{ minute: 30 },
			{ hour: 1 },
			{ hour: 3 },
			{ hour: 6 },
			{ hour: 12 },
			{ day: 1 },
		];
		ranges.forEach((range) => {
			range.toLabel = {};
		});

		const matchesRange = (range, hour, minute) => {
			if (range.minute) {
				return minute % range.minute === 0;
			} if (range.hour) {
				return !minute && hour % range.hour === 0;
			}
			return !hour && !minute;
		};

		ticks.forEach((tick) => {
			if (!tick.label) {
				return;
			}
			const [date, hourMinStr] = tick.label.split(' ');
			const [hourStr, minuteStr] = (hourMinStr || '').split(':');
			const hour = hourStr ? parseInt(hourStr, 10) : 0;
			const minute = minuteStr ? parseInt(minuteStr, 10) : 0;
			ranges.forEach((range) => {
				if (!matchesRange(range, hour, minute)) {
					return;
				}
				let str;
				if (!hour && !minute) {
					str = date;
				} else if (!minute) {
					str = range.minute ? hourMinStr : hourStr;
				} else {
					str = minuteStr;
				}
				range.toLabel[tick.label] = { str, major: !!((!minute && range.minute) || (!hour && range.hour)) };
			});
		});
		// TODO: find out what that's supposed to to and rewrite it
		const { toLabel } = ranges.find((r) => _.keys(r.toLabel).length <= maxTicks && !(!hasMinute && r.minute)) || _.last(ranges);
		if (_.keys(toLabel).length > maxTicks) {
			ticks.forEach((tick) => {
				if (tick.label) {
					tick.label = tick.label.split(' ')[0];
				}
			});
			return true;
		}
		_.forEachRight(ticks, (tick, idx) => {
			if (tick.label) {
				const label = toLabel[tick.label];
				if (label) {
					tick.label = label.str;
					tick.major = label.major;
				} else {
					ticks.splice(idx, 1);
				}
			}
		});
		return false;
	};

	const calculateScales = () => {
		const options = (type === 'pie' || groupBy[levels - 1] !== 'date')
			? {}
			: {
				scales: {
					x: {
						afterCalculateLabelRotation: shortRange && ((scale) => {
							const { ticks, chart } = scale;
							// Only use special tick customization for vertical bar charts
							if (chart.config.options.indexAxis !== 'y') {
								const autoSkip = tickCustomizeDates(
									ticks,
									shortRange,
								);
								if (autoSkip) {
									scale.options.ticks.autoSkip = true;
								}
							}
						}),
						ticks: {
							autoSkip: !shortRange,
						},
					},
				},
			};
		return options;
	};

	// TODO: memoize
	const options = createOptions(
		data,
		type,
		currency,
		showLegend,
		{ ...extraOptions, ...calculateScales() },
		stacked,
		barChartType,
		showEventLines ? events : [],
	);
	return (
		<div ref={ref}>
			<Box position="relative">
				<h3>{description}</h3>
				<Box>
					{type === 'bar' ? <BarChart data={data} options={options} /> : null}
					{type === 'line' ? (
						<LineChart data={data} options={options} />
					) : null}
				</Box>
				{type === 'pie' ? (
					<Box sx={{ height: 450 }}>
						<Pie
							data={{
								...data,
								datasets: [{
									// Limit pie charts to a single dataset as multiple sets make no visual sense
									...data.datasets[0],
									borderColor: 'transparent',
									// Override colors because provided values are all identical
									backgroundColor: data.datasets[0].data
										.map((d, i) => getColor(i, false, data.datasets[0].data.length)),
									hoverBackgroundColor: data.datasets[0].data
										.map((d, i) => getColor(i, false, data.datasets[0].data.length)),
								}],
							}}
							options={{ ...options, maintainAspectRatio: false }}
						/>
					</Box>
				) : null}
			</Box>
		</div>
	);
}

ReportChart.propTypes = {
	data: PropTypes.exact({
		datasets: PropTypes.arrayOf(PropTypes.object),
		labels: PropTypes.arrayOf(PropTypes.any),
	}).isRequired,
	currency: PropTypes.string,
	description: PropTypes.string,
	showLegend: PropTypes.bool,
	options: PropTypes.objectOf(PropTypes.any),
	stacked: PropTypes.bool.isRequired,
	barChartType: PropTypes.oneOf(['vertical', 'horizontal']),
	showEventLines: PropTypes.bool,
	events: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		date: PropTypes.instanceOf(Date),
		public: PropTypes.bool,
		createdDate: PropTypes.string,
		tags: PropTypes.arrayOf(PropTypes.string),
		authorInfo: PropTypes.shape({ fullname: PropTypes.string }),
	})),
	levels: PropTypes.number.isRequired,
	shortRange: PropTypes.shape({ ms: PropTypes.number }),
	groupBy: PropTypes.arrayOf(PropTypes.any).isRequired,
};

ReportChart.defaultProps = {
	currency: 'EUR',
	options: {},
	showLegend: false,
	description: '',
	barChartType: 'vertical',
	showEventLines: false,
	events: [],
	shortRange: undefined,
};
