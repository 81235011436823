import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Route } from 'react-router-dom';
import { CustomImportFields } from 'relevant-shared/mappingDimensions/allDimensionArrays';
import styles from './styles.css';
import BrowserUtils from '../../lib/browserUtils';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import ProgrammaticCommonSettings from '../../containers/ProgrammaticCommonSettings';
import {
	SspList, AdserverList, AnalyticsList, UserIdModulesList,
} from '../../components/SystemLists';
import { FormOf, IfHasHbAnalytics } from '../../components/Wrappers';
import PopupSelector from '../../components/PopupSelector';
import {
	GlobalSettingsObject,
	Cluster,
	DocumentTemplate,
	Ssp,
	Adserver,
	SummaryHbData,
	AnalyticsSystems,
	UserIdModule,
} from '../../api/relevant';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';
import SystemData from '../../lib/systemData';
import { CorrectionSelector } from '../../components/Report/utils';
import FieldArray from '../../components/FieldArray';
import TextField from '../../components/TextField';
import Credentials from '../../components/Credentials';
import TechFeeSetup from '../../components/TechFeeSetup';
import ClusterEdit from '../../components/ClusterEdit';
import ExpandSelector from '../../components/ExpandSelector';
import ReactUtils from '../../lib/reactUtils';
import DatePicker from '../../components/DatePicker';
import UserReportOptions from '../../components/Report/userReportOptions';
import ExternalDataImport from '../../components/ExternalDataImport';
import {
	AdserverEdit, SspEdit, AnalyticsEdit, UserIdModulesEdit,
} from '../SimplePages';
import SimpleOperationWrapper from '../../components/SimpleOperationWrapper';
import withUseRequestHook from '../../hooks/useRequest/wrapper';

const HAVE_PROGRAMMATIC_COMMON_SETTINGS = false;

const GLOBAL_MAIL_TEMPLATES = {
	userCredentialsTemplate: {
		label: 'User credentials template',
		type: 'UserCredentialsData',
	},
	recoverCredentialsTemplate: {
		label: 'Recover credentials template',
		type: 'RecoverCredentialsData',
	},
};

class GlobalSettingsProgrammatic extends React.Component {
	constructor(props) {
		super(props);
		this.state = { globalSettings: {}, cluster: {}, docTemplates: [] };
	}

	componentDidMount() {
		const { publisherId } = BrowserUtils.urlParams();
		this.props.setRequest({
			requestFunction: () => Promise.all([
				Ssp.call('getAll', { publisherId }),
				Adserver.call('getAll', { publisherId }),
				AnalyticsSystems.call('getAll', { publisherId }),
				UserIdModule.call('getAll', { publisherId }),
			]),
			requestCallback: ([ssps, adservers, analytics, userIdModules]) => (
				this.setState({
					ssps, adservers, analytics, userIdModules,
				})
			),
		});
	}

	/**
	 * Added, deleted ssp/adserver etc
	 * */
	onSystemsChange(type) {
		const { publisherId } = BrowserUtils.urlParams();
		switch (type) {
			case 'ssps':
				this.props.setRequest({
					requestFunction: () => Ssp.call('getAll', { publisherId }),
					requestCallback: (ssps) => {
						this.setState({ ssps });
					},
				});
				break;
			case 'adservers':
				this.props.setRequest({
					requestFunction: () => Adserver.call('getAll', { publisherId }),
					requestCallback: (adservers) => {
						this.setState({ adservers });
					},
				});
				break;
			case 'analytics':
				this.props.setRequest({
					requestFunction: () => AnalyticsSystems.call('getAll', { publisherId }),
					requestCallback: (analytics) => {
						this.setState({ analytics });
					},
				});
				break;
			case 'userId':
				this.props.setRequest({
					requestFunction: () => UserIdModule.call('getAll', { publisherId }),
					requestCallback: (userIdModules) => {
						this.setState({ userIdModules });
					},
				});
				break;
			default:
				break;
		}
	}

	renderCustomImportDimSettings({ model, form, field }) {
		const update = () => form.update();
		return (
			<Grid item xs={12}>
				<ExpandSelector
					title="Custom import dimension"
					form={form}
				>
					<FieldArray
						model={model}
						field={field}
						name="customimportValues"
						addBtnText="Add new possible value of dimension"
						update={update}
						createNew={() => new SystemData.models.CustomImportValue({ name: 'New value' })}
						render={(fld, elm) => (
							<Grid container spacing={3}>
								<Grid item xs={4}>
									<TextField
										required
										label="Name"
										{...fld('name')}
										fullWidth
									/>
								</Grid>
								<Grid item xs={4}>
									<PopupSelector
										size="lg"
										title="Select conditions for value"
										selected={elm.ands}
										textFieldAlign
										form={form}
									>
										<FieldArray
											model={elm}
											field={fld}
											name="ands"
											addBtnText="Add condition"
											update={update}
											createNew={() => ({ dim: 'placementName', ors: [] })}
											render={(fld, elm) => (
												<>
													<Select
														fullWidth
														label="Import dimension"
														{...fld('dim')}
														items={CustomImportFields.map((d) => ({ label: d.desc, value: d.fld }))}
													/>
													<ExpandSelector
														title="Strings to match"
														selected={elm.ors}
														form={form}
													>
														<FieldArray
															model={elm}
															field={fld}
															name="ors"
															addBtnText="Add string to match"
															update={update}
															createNew={() => ({ str: '' })}
															render={(fld) => (
																<>
																	<TextField required label="String" {...fld('str')} />
																	<Checkbox
																		style={{ marginLeft: 10 }}
																		label="Negative condition"
																		{...fld('isNot')}
																	/>
																	<Checkbox label="Only full words" {...fld('fullWord')} />
																	<Checkbox label="Exact match" {...fld('exact')} />
																	<Checkbox label="Case sensitive" {...fld('caseSensitive')} />
																</>
															)}
														/>
													</ExpandSelector>
												</>
											)}
										/>
									</PopupSelector>
								</Grid>
								<Grid item xs={4}>
									<Checkbox
										label="Use OR condition (only one must match)"
										{...fld('useOrForAnd')}
									/>
								</Grid>
							</Grid>
						)}
					/>
				</ExpandSelector>
			</Grid>
		);
	}

	renderSummaryHbImport() {
		const settings = {
			forceImportLowRevData: false,
			forceImportLowAdsData: false,
		};
		return (
			<FormOf
				model={settings}
				content={({ field }) => (
					<Grid item xs={12}>
						<ExpandSelector title="HB Analytics settings">
							<ExternalDataImport
								description="Import HB Summary data"
								model={{
									importData: (p) => SummaryHbData.call('importData', { ...p, ...settings }),
								}}
								beforeImportBtn={() => (
									<Grid item>
										<Checkbox
											label="Overwrite historical HB data even if previous recorded data had higher revenue"
											{...field('forceImportLowRevData')}
										/>
										<Checkbox
											label="Overwrite historical Adserver data even if previous such data had higher revenue"
											{...field('forceImportLowAdsData')}
										/>
									</Grid>
								)}
							/>
						</ExpandSelector>
					</Grid>
				)}
			/>
		);
	}

	renderProgrammaticOldDataSettings({ field, form }) {
		return (
			<ExpandSelector title="Limit display of old ARI report data" form={form}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<DatePicker
							floatingLabelText="Hide all ARI report data before"
							autoOk
							canClear
							{...field('hideProgrammaticDataBefore')}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Hide all ARI report data for normal/publisher users after this many days"
							integer
							fullWidth
							{...field('programmaticDataDaysPubUser')}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Require extra click for normal/publisher users when pulling ARI-reports older than (days)"
							integer
							fullWidth
							{...field('programmaticWarnDaysPubUser')}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Require extra click for administrator users when pulling ARI-reports older than (days)"
							integer
							fullWidth
							{...field('programmaticWarnAdminUser')}
						/>
					</Grid>
				</Grid>
			</ExpandSelector>
		);
	}

	render() {
		const {
			history, loading, error, reset,
		} = this.props;
		const {
			globalSettings, cluster, docTemplates, ssps, adservers, analytics, userIdModules,
		} = this.state;
		const { publisherId } = BrowserUtils.urlParams();
		const { CURRENCIES, LANGUAGES } = SystemData.genericData;

		return (
			<SimpleOperationWrapper
				loading={loading}
				error={error}
				onErrorModalClick={reset}
			>
				{/* Child modal routes */}
				<Route
					exact
					path={`${this.props.match.path}/adservers/:id`}
					render={(props) => <AdserverEdit {...props} onChange={() => this.onSystemsChange('adservers')} />}
				/>
				<Route
					exact
					path={`${this.props.match.path}/ssps/:id`}
					render={(props) => <SspEdit {...props} onChange={() => this.onSystemsChange('ssps')} />}
				/>
				<Route
					exact
					path={`${this.props.match.path}/analytics/:id`}
					render={(props) => <AnalyticsEdit {...props} onChange={() => this.onSystemsChange('analytics')} />}
				/>
				<Route
					exact
					path={`${this.props.match.path}/user-id-modules/:id`}
					render={(props) => <UserIdModulesEdit {...props} onChange={() => this.onSystemsChange('userId')} />}
				/>
				<div className={styles.pageContentWrapper}>
					<Container maxWidth="xl">
						<Grid container spacing={3}>
							<Grid item md={6} xs={12}>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<SspList
											list={ssps}
											publisherId={publisherId}
											onSystemsChange={() => this.onSystemsChange('ssps')}
										/>
									</Grid>
									{!publisherId && (
										<FormOf
											model={globalSettings}
											content={(p) => (
												<Grid item xs={12}>
													<PopupSelector
														size="lg"
														title="Generic programmatic settings"
														fn={async () => {
															const [gs, dt] = await Promise.all([
																GlobalSettingsObject.listOne(),
																DocumentTemplate.list({ toObjectProfile: 'header' }),
															]);
															Object.assign(globalSettings, gs.toLegacyObject());
															docTemplates.splice(0, docTemplates.length);
															docTemplates.push(...dt);
															p.form.update();
														}}
														form={p.form}
														onApplyChanges={() => globalSettings.update(_.pick(
															globalSettings,
															p.form.rootFields().concat([
																'techFeeSetup',
																'userReportOptions',
																'bidParams',
															]),
														))}
														content={() => (
															<Grid container spacing={3}>
																<Grid item xs={12}>
																	<Select
																		{...p.field('programmaticDefaultCurrency')}
																		label="Default report currency"
																		nonSelected="(default)"
																		items={_.map(CURRENCIES, (v, k) => ({ label: `${v.name} (${k})`, value: k }))}
																		fullWidth
																	/>
																</Grid>
																<Grid item xs={12}>
																	<CorrectionSelector field={p.field} />
																</Grid>
																<Grid item xs={12}>
																	<Checkbox
																		{...p.field('includeSiteInPlacementNames')}
																		label="Include site name in placement name in reports"
																	/>
																</Grid>
																<Grid item xs={12}>
																	{this.renderProgrammaticOldDataSettings(p)}
																</Grid>
																<Grid item xs={12}>
																	<Checkbox
																		{...p.field('delaySspEntryUpdate')}
																		label="Delay update of ARI report cache after updating accounts, SSPs, etc"
																		help="This delays reflection of recent changes into report - until manual intervetion or until the next daily import. Purpose is to not cause slow performance after saving e.g. accounts."
																	/>
																</Grid>
																<Grid item xs={12}>
																	<TextField
																		{...p.field('stopRetryImportHour')}
																		required
																		fullWidth
																		between={{ low: 0, high: 23 }}
																		label="Stop re-trying API imports of SSPs missing data at this UTC hour"
																		help="Before sending API mail reports the system waits for data for SSPs that had data the day before yesterday, but not yesterday - until this hour (UTC)"
																	/>
																</Grid>
																<Grid item xs={12}>
																	<ExpandSelector
																		title="Technical cost setup"
																		form={p.form}
																	>
																		<TechFeeSetup
																			model={globalSettings.techFeeSetup}
																			field={(path) => p.field(`techFeeSetup.${path}`)}
																			form={p.form}
																		/>
																	</ExpandSelector>
																</Grid>
																{!!SystemData.genericData?.CUSTOMIMPORTDIM?.enabled
																	&& this.renderCustomImportDimSettings(p)}
																<Grid item xs={12}>
																	<UserReportOptions
																		model={globalSettings.userReportOptions}
																	/>
																</Grid>
																<Grid item xs={12}>
																	<ExpandSelector title="Mail templates">
																		<Grid container spacing={3}>
																			<Grid item xs={12}>
																				<Select
																					label="Alarm mail template"
																					{...p.field('triggerMailTemplate')}
																					nonSelected="(default)"
																					items={docTemplates.filter((t) => t.type === 'TriggerMailData').map((t) => ({ label: t.name, value: t.id }))}
																					fullWidth
																					margin="normal"
																				/>
																			</Grid>
																			{ReactUtils.fldMapObj(
																				p.model,
																				p.field,
																				'defaultTemplates',
																				(obj, fld, key) => (
																					<Grid key={key} item xs={12}>
																						<ExpandSelector title={(LANGUAGES[key] || {}).name}>
																							{_.map(GLOBAL_MAIL_TEMPLATES, ({ label, type }, fldName) => (
																								<Select
																									key={fldName}
																									label={label}
																									{...fld(fldName)}
																									nonSelected="(default)"
																									items={docTemplates.filter((t) => t.type === type).map((t) => ({ label: t.name, value: t.id }))}
																									fullWidth
																									margin="normal"
																								/>
																							))}
																						</ExpandSelector>
																					</Grid>
																				),
																			)}
																		</Grid>
																	</ExpandSelector>
																</Grid>
																<IfHasHbAnalytics>
																	{this.renderSummaryHbImport()}
																</IfHasHbAnalytics>
															</Grid>
														)}
													/>
												</Grid>
											)}
										/>
									)}
									{!publisherId && (
										<FormOf
											model={cluster}
											content={(p) => (
												<Grid item xs={12}>
													<PopupSelector
														title="Header bid analytics servers"
														fn={async () => {
															Object.assign(cluster, (await Cluster.call('getMyCluster')).toLegacyObject());
															p.form.update();
														}}
														form={p.form}
														onApplyChanges={async () => {
															await cluster.update();
															this.forceUpdate();
														}}
														content={(popup) => (
															<ClusterEdit
																popup={popup}
																cluster={cluster}
																field={p.field}
																form={p.form}
															/>
														)}
														size="sm"
													/>
												</Grid>
											)}
										/>
									)}
									{publisherId && (
										<Grid item xs={12}>
											<ActionButton
												label="Cancel"
												color="secondary"
												onClick={() => history.push(`/accounts/${publisherId}`)}
											/>
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid item md={6} xs={12}>
								<Grid container spacing={3}>
									{!publisherId && HAVE_PROGRAMMATIC_COMMON_SETTINGS
									&& (
										<Grid item xs={12}>
											<ProgrammaticCommonSettings {...this.props} />
										</Grid>
									)}
									<Grid item xs={12}>
										<AdserverList
											list={adservers}
											publisherId={publisherId}
											onSystemsChange={() => this.onSystemsChange('adservers')}
										/>
									</Grid>
									<Grid item xs={12}>
										<AnalyticsList
											list={analytics}
											publisherId={publisherId}
											onSystemsChange={() => this.onSystemsChange('analytics')}
										/>
									</Grid>
									<Grid item xs={12}>
										<UserIdModulesList
											list={userIdModules}
											publisherId={publisherId}
											onSystemsChange={() => this.onSystemsChange('userId')}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</div>
			</SimpleOperationWrapper>
		);
	}
}

GlobalSettingsProgrammatic.propTypes = {
	history: PropTypes.object.isRequired,
};

GlobalSettingsProgrammatic.defaultProps = {

};

export default withUseRequestHook(GlobalSettingsProgrammatic);
