import { Check, Close, ErrorOutline, WarningAmberOutlined, WbIncandescentOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useAnimationFrame } from '../../hooks/useAnimationFrame';
import { useToast } from '../../hooks/useToast';

export type ToastProps = {
	open?: boolean;
	message: string | React.ReactNode | ((close) => React.ReactNode);
	status: 'success' | 'info' | 'warning' | 'error';
	transition?: React.ElementType;
	action?: React.ReactNode;
	autoHideDuration?: number;
	closeable?: boolean;
	onClose?: () => void;
	icon?: React.ReactNode;
	AlertProps?: object;
	anchorOrigin?: {
		vertical: 'top' | 'bottom';
		horizontal: 'left' | 'center' | 'right';
	};
	timeout?: number; // seconds
	showCountdown?: boolean;
	inline?: boolean;
};

export function Toast(props: ToastProps & HTMLAttributes<HTMLDivElement>) {
	const {
		open,
		message,
		transition,
		action,
		autoHideDuration,
		closeable = true,
		onClose,
		status,
		icon,
		AlertProps,
		anchorOrigin,
		timeout,
		showCountdown,
		inline,
		...rest
	} = props;

	const [visible, setVisible] = useState(open);
	const [countdown, setCountdown] = useState(timeout);
	const [paused, setPaused] = useState(!open);

	const close = () => {
		setVisible(false);
		onClose?.();
	};

	useAnimationFrame(({ delta }) => {
		if (!timeout || paused) return;
		setCountdown((prev) => prev - delta);
		if (countdown <= 0) {
			close();
		}
	});

	useEffect(() => {
		setCountdown(timeout);
	}, [timeout]);

	if (inline !== true) {
		// By default, render toasts through useToast and ToastProvider instead of directly in the DOM
		const [_, showToast] = useToast();
		useEffect(() => {
			if (open) {
				showToast({
					...props,
					anchorOrigin: undefined
				});
			}
		}, [open]);
		return null;
	} else {
		return (
			<div
				className={clsx(
					"relative inline-flex flex-row items-start gap-3 z-[1500] max-w-[500px]",
					"border rounded-md shadow-md p-4",
					"font-normal text-sm",
					!visible && "hidden",
					{
						"!fixed m-4 mt-20": anchorOrigin,
						"top-0": anchorOrigin?.vertical === 'top',
						"bottom-0": anchorOrigin?.vertical === 'bottom',
						"left-0": anchorOrigin?.horizontal === 'left',
						"right-0": anchorOrigin?.horizontal === 'right',
						"mx-auto": anchorOrigin?.horizontal === 'center',
					},
					{
						"bg-success-green-5 border-success-green-60 text-success-green-80": status === 'success',
						"bg-warning-yellow-5 border-warning-yellow-70 text-warning-yellow-80": status === 'warning',
						"bg-failure-red-5 border-failure-red-60 text-failure-red-80": status === 'error',
						"bg-success-blue-5 border-success-blue-60 text-success-blue-80": status === 'info',
					}
				)}
				onMouseOver={() => setPaused(true)}
				onMouseLeave={() => setPaused(false)}
				{...rest}
			>
				{icon}
				{icon !== null && (
					<>
						{status === 'success' && <Check />}
						{status === 'warning' && <WarningAmberOutlined />}
						{status === 'error' && <ErrorOutline />}
						{status === 'info' && <WbIncandescentOutlined className="rotate-180" />}
					</>
				)}
				<div className="mt-auto mb-auto">
					{message instanceof Function ? message(close) : message}
				</div>
				{closeable && (
					<button onClick={close}><Close /></button>
				)}
				{timeout && showCountdown && (
					<div
						className={clsx(
							"absolute bottom-0 left-[3px] right-[3px] h-[1px]", // Can't use overflow-hidden to clip the bar to the rounded borders cause that messes up auto-animate for some reason
							{
								"bg-success-green-80": status === 'success',
								"bg-warning-yellow-80": status === 'warning',
								"bg-failure-red-80": status === 'error',
								"bg-success-blue-80": status === 'info',
							}
						)}
						style={{ width: `${countdown / timeout * 100}%` }}
					/>
				)}
			</div>
		);
	}
}

Toast.defaultProps = {
	open: true,
	message: '',
	icon: null,
	transition: undefined,
	action: null,
	status: undefined,
	autoHideDuration: undefined,
	AlertProps: {},
	onClose: undefined,
	anchorOrigin: undefined,
};
