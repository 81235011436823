import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styles from './styles.css';
import { Dialog } from '../Dialog';

/**
 * Works similarly to OperationWrapper, but does not have as much functionality.
 */
function SimpleOperationWrapper({
	loading,
	error,
	onErrorModalClick,
	children,
	containerClass,
	loadingContainerProps,
	hideOnError,
}) {
	const containerClassName = containerClass ? ` ${containerClass}` : '';
	return (
		<>
			{!(hideOnError && error.state) && (
				<Box
					className={containerClassName}
					component="div"
					width="100%"
					height="100%"
					minHeight="100px" // make sure spinner is fully visible
					position="relative"
					{...loadingContainerProps}
				>
					{ loading
						&& (
							<CircularProgress
								className={styles.spinner}
								size={100}
							/>
						)}
					<div className={`${loading ? styles.disabledContent : null}`}>
						{ children }
					</div>
				</Box>
			)}
			{error.state && (
				<Dialog
					open={error.state}
					text={error.message}
					onClose={onErrorModalClick}
				/>
			)}
		</>
	);
}

SimpleOperationWrapper.propTypes = {
	containerClass: PropTypes.string,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.shape({
		state: PropTypes.bool,
		message: PropTypes.string,
	}),
	onErrorModalClick: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	loadingContainerProps: PropTypes.objectOf(PropTypes.any),
	hideOnError: PropTypes.bool,
};

SimpleOperationWrapper.defaultProps = {
	containerClass: '',
	error: {
		state: false,
		message: 'An error occured. Please try again.',
	},
	hideOnError: true,
};

export default SimpleOperationWrapper;
