import React from 'react';
import config from 'appconfig';
import _ from 'lodash';
import { getObjectDiff } from 'relevant-shared/misc/objectDiff';
import { getIdentifier, setByIdentifier, asyncFuncCache } from 'relevant-shared/misc/misc';
import BrowserUtils from '../lib/browserUtils';
import MiscUtils from '../lib/miscUtils';
import { relevant, createApiInterface } from './index';
import { withMsgChannel } from './msgChannelUtils';
import { stores } from '../stores';

let clsMap;

// Weak map that keeps track on the "original" plain objects received from the server.
// Only used for the main-documents (like Publisher), not sub-documents. Purpose is to generate
// "diffs" when doing updates (like saving a publisher..).
const rootApiObjects = new WeakMap();

const EVENTS = ['onCreated', 'onDeleted', 'onAllListed'];

const createCls = (clsName) => {
	const cls = eval(`(() => class ${clsName} extends ApiObject {})()`);
	Object.assign(cls, {
		events: Object.fromEntries(EVENTS.map((e) => [e, MiscUtils.listeners()])),
		clsName: () => clsName,
	});
	return cls;
};

let storesCache;
const getStores = () => {
	if (!storesCache) {
		// eslint-disable-next-line global-require
		storesCache = stores;
	}
	return storesCache;
};

const userPrefixed = (path) => getStores().identity.getUrlPathPrefix() + path;

const API_URL = _.trimEnd(config.apis.relevant, '/');

const isApiObject = (v) => (v && v.__clsN);

const toApiObject = (v, settings) => {
	if (isApiObject(v)) {
		const Cls = clsMap[v.__clsN];
		if (Cls) {
			return new Cls(v, true, settings);
		}
	}
	return undefined;
};

const cloneApiData = (obj, settings = {}) => _.cloneDeepWith(obj, (v) => {
	if (v === obj && settings.noSelfClone) {
		return undefined;
	}
	return toApiObject(v, settings);
});

const apisByEndpoint = {};

const getApi = ({ apiEndpoint } = {}) => {
	if (!apiEndpoint) {
		return relevant; // default
	}
	if (!apisByEndpoint[apiEndpoint]) {
		apisByEndpoint[apiEndpoint] = createApiInterface(apiEndpoint); // from relevant-frontend/src/api/index.js
	}
	return apisByEndpoint[apiEndpoint];
};

// eslint-disable-next-line prefer-const
const toApiResult = async function (op, settings) {
	const { result } = (await op) || {};
	if (result) { // objects might be nestled anywhere in result, let's find them
		if (result.deepCheckObject) {
			return cloneApiData(result, { fromApiResult: true, deepCreate: true, ...settings });
		}
		if (isApiObject(result)) {
			return cloneApiData(result, { fromApiResult: true, ...settings });
		}
		if (_.isArray(result)) {
			return result.map((elm) => (
				(isApiObject(elm) ? cloneApiData(elm, { fromApiResult: true, ...settings }) : elm)
			));
		}
	}
	return result;
};

// eslint-disable-next-line no-unused-vars
class ApiObject {
	constructor(obj, objIsExistingModel, settings = {}) {
		let s = settings;
		if (this.constructor.alwaysDeepCreate && !s.deepCreate) {
			s = { ...settings, deepCreate: true };
		}
		this.initInternal(obj, objIsExistingModel, s);
		this.postInit?.();
		this.trigger('onCreated', this);
	}

	static trigger(event, ...args) {
		const cls = this;
		cls.events[event]?.notify(...args);
		const dbCls = cls.getDbOpCls();
		if (dbCls && dbCls !== cls) {
			// To make it possible to listen to either AdformSsp or BaseSsp, etc (boths' .events will be used)
			dbCls.events[event]?.notify(...args);
		}
	}

	trigger(event, ...args) {
		this.constructor.trigger(event, this, ...args);
	}

	initInternal(obj, objIsExistingModel, settings) {
		if (settings.fromApiResult) {
			const { rootApiObject } = settings;
			Object.assign(this, settings.deepCreate ? cloneApiData(obj, {
				rootApiObject: rootApiObject || this,
				...settings,
				noSelfClone: true,
			}) : obj);
			delete this.__clsN;
			if (!rootApiObject) {
				rootApiObjects.set(this, this.asDiffCompatibleObject());
			}
			return;
		}
		const cls = this.constructor;
		if (!cls.clsInfo) {
			throw Error(`Class '${cls.clsName()}' not initialized`);
		}
		const { empty } = cls.clsInfo;
		const ignore = {};
		const customizer = (v) => {
			if (v && v.id && v._id && !ignore[v.id]) {
				ignore[v.id] = true;
				const res = _.cloneDeepWith(v, customizer);
				const id = MiscUtils.objectIdStr();
				res.id = id;
				res._id = id;
				return res;
			}
			return undefined;
		};
		if (empty) {
			Object.assign(this, _.cloneDeepWith(objIsExistingModel ? obj : empty, customizer));
		} else {
			throw Error(`Can't create empty object of type '${cls.clsName()}'`);
		}
		if (!objIsExistingModel) {
			Object.assign(this, obj);
		} else {
			this.isNew = true;
		}
		delete this.__clsN;
	}

	asDiffCompatibleObject() {
		return JSON.parse(JSON.stringify(this, (key, val) => {
			if (val instanceof ApiObject) {
				const identifier = val.getIdentifier();
				if (identifier) {
					// We're k
					return { ...val, _diffIdentifier: identifier, _diffType: val.constructor.clsName() };
				}
			} else if (val instanceof React.Component) {
				return undefined;
			}
			return val;
		}));
	}

	getOriginalApiObject() {
		return rootApiObjects.get(this);
	}

	getApiDiff() {
		const org = rootApiObjects.get(this);
		return org ? getObjectDiff(org, this.asDiffCompatibleObject()) : null;
	}

	// Add update, delete, etc directly on the object (needed for some old stupid code)
	toLegacyObject() {
		return { ...this.constructor.prototype, ...this };
	}

	// This function returns undefined for all classes without 'identifierFields' (the normal case)
	getIdentifier() {
		return getIdentifier(this, this.constructor.clsInfo.identifierFields);
	}

	setByIdentifier(identifier) {
		setByIdentifier(this, this.constructor.clsInfo.identifierFields, identifier);
	}

	static async save(obj, settings) {
		if (obj.isNew) {
			const cls = obj.constructor;
			if (cls && cls.getDbOpCls) {
				const dbCls = cls.getDbOpCls();
				if (dbCls) {
					await dbCls.add(obj);
					obj.isNew = false;
					return;
				}
			}
		} else if (obj.update) {
			await obj.update(undefined, settings);
			return;
		}
		throw Error('Invalid object supplied to ApiObject.save()');
	}

	static clsInitialize() {
		const cls = this;
		const {
			apiPath, dbApiPath, isPathOwner, isDbPathOwner, staticCommands = [], instanceCommands = [],
		} = cls.clsInfo;
		const pathName = () => userPrefixed(apiPath);
		const dbPathName = () => userPrefixed(dbApiPath);
		if (isPathOwner) { // Add own static commands
			Object.assign(cls, {
				callStatic: async (cmd, param = {}) => {
					const data = { ...param, customCommand: cmd, ...withMsgChannel(param, clsMap) };
					return toApiResult(getApi(data).post(`/${pathName()}`, data));
				},
				asUrl: (cmd, params = {}, id = undefined) => BrowserUtils.makeQs(
					`${API_URL}/${pathName()}/${id || 'cmd'}`,
					{ cmd, params: btoa(JSON.stringify(params)) },
				),
				fn: (name) => cls.callStatic.bind(cls, name),
			});
			staticCommands.forEach((cmd) => {
				cls[cmd] = (...params) => cls.callStatic(cmd, ...params);
			});
		}
		if (isDbPathOwner) { // Add db-operations
			Object.assign(cls, {
				get: (id, settings) => toApiResult(getApi(settings).get(`/${dbPathName()}/${id || 'empty'}`), settings),
				list: async (query, settings) => {
					const qsString = _.reduce(
						query,
						(result, value, key) => `${result}${key}=${encodeURIComponent(value)}&`,
						'?',
					);
					const path = `/${dbPathName()}${qsString}`;
					const res = await toApiResult(getApi(settings).get(path), settings);
					if (_.isEmpty(query)) {
						cls.trigger('onAllListed', res);
					}
					return res;
				},
				listOne: async (query, settings) => (await cls.list(query, settings))[0],
				add: (body) => getApi(body).post(`/${dbPathName()}`, body),
			});
		}
		if (apiPath) { // add instance commands
			Object.assign(cls.prototype, {
				// eslint-disable-next-line object-shorthand
				update: async function (mergeObj, settings) {
					const {
						updateUsingDiff = false, // TODO: set to true as default after more throughout testing..
					} = settings || {};
					let param = mergeObj || this;
					const useDiff = !mergeObj && updateUsingDiff && this.asDiffCompatibleObject;
					if (useDiff) {
						param = {
							...this.getApiDiff(),
							updateParams: { returnUpdatedObject: true }, // The updated object might differ from this
						};
					}
					const res = await toApiResult(relevant.put(`/${dbPathName()}/${this.id}`, param), settings);
					if (!mergeObj && this.asDiffCompatibleObject) {
						// If we're creating a new diff it should be based of this object's current state
						rootApiObjects.set(this, this.asDiffCompatibleObject());
					}
					return res;
				},
				// eslint-disable-next-line object-shorthand
				delete: async function () {
					await relevant.delete(`/${dbPathName()}/${this.id}`);
					this.trigger('onDeleted');
				},
			});
			instanceCommands.forEach((cmd) => {
				cls.prototype[cmd] = async function (body = {}) {
					const data = { ...body, customCommand: cmd, ...withMsgChannel(body, clsMap) };
					return toApiResult(relevant.put(`/${pathName()}/${this.id}`, data));
				};
			});
		}
	}

	static getParent() {
		return clsMap[this.clsInfo.parent];
	}

	static get fnCache() {
		if (this.__fnCache?.obj !== this) {
			this.__fnCache = asyncFuncCache(this);
		}
		return this.__fnCache;
	}

	static onAfterSystemDataInvalidated() {
		this.__fnCache?.reset();
	}

	static getDbOpCls() {
		for (let cls = this; cls; cls = cls.getParent()) {
			if (cls.clsInfo.isDbPathOwner) {
				return cls;
			}
		}
		return null;
	}

	static async initializeClasses(clsInfoMap) {
		const classes = [];
		_.forOwn(clsInfoMap, (clsInfo, clsName) => {
			clsMap[clsName] = clsMap[clsName] || createCls(clsName);
			const cls = clsMap[clsName];
			cls.clsInfo = clsInfo;
			classes.push(cls);
		});
		classes.forEach((cls) => cls.clsInitialize());
		const pathOwners = _.values(clsMap).filter(({ clsInfo }) => clsInfo && clsInfo.isPathOwner);
		ApiObject.clsByPath = _.keyBy(pathOwners, ({ clsInfo }) => clsInfo.apiPath.toLowerCase());
	}
}

clsMap = { ApiObject };

const proxy = new Proxy({}, {
	get: (__, clsName) => {
		// eslint-disable-next-line no-prototype-builtins
		if (clsMap.hasOwnProperty(clsName)) {
			return clsMap[clsName];
		}
		clsMap[clsName] = createCls(clsName);
		return clsMap[clsName];
	},
	has: (__, key) => key in clsMap,
});

clsMap.apiClassMap = clsMap;
export default proxy;
