import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { cloneOrgValue } from 'relevant-shared/prebid/bidParamUtils';
import ByType from './byType';
import ReactUtils from '../../lib/reactUtils';
import Switch from '../Switch';
import Form from '../../containers/Form';
import { Scope } from '../Wrappers';
import { stores } from '../../stores';
import SystemData from '../../lib/systemData';

class FieldDataObject extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderSummary(model) {
		const { field } = this.props;
		return (
			ReactUtils.fldMapObj(model, field, (value, fld, name) => (
				<Grid key={name} item xs={12} style={{ width: 'max-content' }}>
					{!ByType[value.type].isContainer && (
						<Typography variant="h4" style={{ display: 'inline' }}>
							{`${value.description}: `}
						</Typography>
					)}
					{ByType[value.type].renderSummary({
						...value,
						value,
						field: fld,
					})}
				</Grid>
			))
		);
	}

	render() {
		const {
			field,
			alwaysOwn,
			inDisabled,
			summary,
			headerOnly,
			renderContext,
		} = this.props;
		let { model } = this.props;

		const { codeFieldsDisabled, codeFieldsDisabledForAdmin } = SystemData.genericData.systemSettings;

		const noCode = codeFieldsDisabled && (codeFieldsDisabledForAdmin || !stores.identity.isSuperAdministrator());
		model = _.pickBy(model, (f) => {
			if (headerOnly && !f.displayInHeader) {
				return false;
			}
			if (noCode && ['JavaScript', 'HTML'].includes(f.type)) {
				return false;
			}
			if (f.shouldHide?.(renderContext || {}, f, model)) {
				return false;
			}
			return true;
		});

		if (summary) {
			return this.renderSummary(model);
		}
		const uiGroupOrder = _.map(model, (o) => o.uiGroup || '');
		const grouped = _.groupBy(_.map(model, (value, name) => ({ value, name })), (o) => o.value.uiGroup || '');
		const sortedGroups = _.sortBy(_.entries(grouped), ([k]) => uiGroupOrder.indexOf(k));
		return sortedGroups.map(([uiGroup, arr]) => {
			const renderArr = () => arr.map(({ name, value }, idx) => {
				const fld = ReactUtils.subFld(field, name);
				const typeInfo = ByType[value.type];
				const isOwnChanged = !!value.isOwnChanged;
				delete value.isOwnChanged;
				const disableField = inDisabled || (!value.isOwn && !alwaysOwn);
				const addDisabler = !value.isOwn && !alwaysOwn && !inDisabled;
				const addInnerDisabler = addDisabler && typeInfo.canInnerDisable;

				if (fld('displayInUi').value === false) {
					return null;
				}
				const showDescription = value.description && (
					value.type === 'Object'
					|| value.type === 'ByObject'
					|| value.type === 'JavaScript'
					|| value.type === 'Array'
					|| value.type === 'HTML'
				);

				return (
					<Grid key={name} item xs={12} style={{ padding: 0 }}>
						{ name === 'loadPbjs' && <h2>Legacy options</h2>}
						<Card style={typeInfo.inlineRender ? { boxShadow: 'none' } : null}>
							<CardContent
								style={{
									...(typeInfo.inlineRender ? { display: 'flex', alignItems: 'center' } : null),
									padding: 0,
									paddingBottom: idx === arr.length - 1 ? 0 : 10,
									paddingTop: 10,
									...typeInfo.cardStyle,
								}}
							>
								<Typography
									variant="h4"
									style={{
										display: 'flex',
										alignItems: 'center',
										marginRight: 8,
										...typeInfo.typographyStyle,
									}}
								>
									{!alwaysOwn && (
										<Switch
											{...fld('isOwn')}
											{...(inDisabled && { onChange: () => {} })}
											style={{ marginRight: 4 }}
											preReaction={() => {
												value.isOwnChanged = true;
												if (value.isOwn) {
													value.prevOwn = _.cloneDeep(value); // Save until toggling back
													if (value.orgValue) { // Replace with inherited values
														Object.assign(value, cloneOrgValue(value.orgValue));
													}
												} else if (value.prevOwn) { // Switch back to previously own value
													Object.assign(value, _.cloneDeep(value.prevOwn));
													delete value.prevOwn;
												}
											}}
											size="small"
											hideLabelIfEmpty
										/>
									)}
									{ showDescription ? `${alwaysOwn ? '\xa0'.repeat(5) : ''}${value.description}` : '' }
								</Typography>
								<div style={{ width: '100%', ...typeInfo.style }} key={value.isOwn}>
									<Box sx={{
										opacity: (addDisabler && !addInnerDisabler) ? 0.5 : 1,
										pointerEvents: (addDisabler && !addInnerDisabler) ? 'none' : 'auto',
									}}
									>
										<FieldDataObject.ExpandMode.Consumer>
											{(expand) => (
												<Scope
													setVal={{
														obj: Form.Context,
														name: 'disableValidation',
														value: disableField,
													}}
													content={() => (
														<>
															{typeInfo.renderData({
																...value,
																value,
																field: fld,
																alwaysOwn,
																inDisabled: disableField,
																isRequired: value.isRequired,
																expand: (isOwnChanged || expand) && value.isOwn,
																addDisabler: addInnerDisabler,
																renderContext,
															})}
														</>
													)}
												/>
											)}
										</FieldDataObject.ExpandMode.Consumer>
									</Box>
								</div>
							</CardContent>
						</Card>
					</Grid>
				);
			});
			return (
				<Fragment key={uiGroup}>
					{!uiGroup ? renderArr() : (
						<Card style={{ width: '100%', marginBottom: 10 }}>
							<CardContent>
								<Typography variant="h2">
									{uiGroup}
								</Typography>
								{renderArr()}
							</CardContent>
						</Card>
					)}
				</Fragment>
			);
		});
	}
}

FieldDataObject.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	alwaysOwn: PropTypes.bool,
	inDisabled: PropTypes.bool,
	summary: PropTypes.bool,
	headerOnly: PropTypes.bool,
	renderContext: PropTypes.object,
};

FieldDataObject.defaultProps = {
	alwaysOwn: false,
	inDisabled: false,
	summary: false,
	headerOnly: false,
	renderContext: undefined,
};

FieldDataObject.ExpandMode = React.createContext(false);

export default FieldDataObject;
