import React, { useCallback, useEffect, useRef, useState } from 'react';

interface AnimateTextProps {
	text: string;
	streaming?: boolean;
	minSpeed?: number; // Minimum characters per second
	maxSpeed?: number; // Maximum characters per second
	speedMultiplier?: number; // Multiplier for calculated speed
}

// A bit more advanced typewriter effect that can handle streaming, and adjusts the speed based on how fast the text updates
export function useTypewriter({
	text,
	streaming = false,
	minSpeed = 50,
	maxSpeed = 200,
	speedMultiplier = 0.8,
}: AnimateTextProps): string {
	// Use refs for state that shouldn't trigger re-renders
	const contentRef = useRef(text);
	const displayedContentRef = useRef('');
	const [displayedContent, setDisplayedContent] = useState('');
	const animationFrameRef = useRef<number>();
	const lastUpdateTimeRef = useRef<number>(Date.now());
	const isAnimatingRef = useRef(false);
	const targetLengthRef = useRef(0);
	const previousContentRef = useRef('');
	const deltaTimestampsRef = useRef<number[]>([]);
	const deltaLengthsRef = useRef<number[]>([]);
	const speedRef = useRef(50); // Default starting speed

	// Initialize with full content if not streaming and on first mount
	useEffect(() => {
		if (!streaming && previousContentRef.current === '') {
			displayedContentRef.current = text;
			setDisplayedContent(text);
			targetLengthRef.current = text.length;
			previousContentRef.current = text;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const animate = useCallback(() => {
		if (!isAnimatingRef.current) {
			return;
		}

		const now = Date.now();
		const deltaTime = now - lastUpdateTimeRef.current;
		// Use dynamic speed from speedRef
		const charsToAdd = Math.max(1, Math.ceil((speedRef.current * deltaTime) / 1000));

		const currentContent = contentRef.current;
		const currentDisplayed = displayedContentRef.current;
		const targetLength = Math.min(targetLengthRef.current, currentContent.length);

		if (currentDisplayed.length < targetLength) {
			const nextContent = currentContent.slice(0, currentDisplayed.length + charsToAdd);
			displayedContentRef.current = nextContent;
			setDisplayedContent(nextContent);
			lastUpdateTimeRef.current = now;
			animationFrameRef.current = requestAnimationFrame(animate);
		} else if (currentContent.length > targetLength) {
			targetLengthRef.current = currentContent.length;
			animationFrameRef.current = requestAnimationFrame(animate);
		} else {
			isAnimatingRef.current = false;
		}
	}, [speedRef]);

	const startAnimation = useCallback(() => {
		if (isAnimatingRef.current) {
			return;
		}

		isAnimatingRef.current = true;
		lastUpdateTimeRef.current = Date.now();
		animationFrameRef.current = requestAnimationFrame(animate);
	}, [animate]);

	useEffect(() => {
		contentRef.current = text;

		if (!streaming) {
			// Immediately set full content when not streaming
			displayedContentRef.current = text;
			setDisplayedContent(text);
			targetLengthRef.current = text.length;
			isAnimatingRef.current = false; // Ensure animation stops
			if (animationFrameRef.current) {
				cancelAnimationFrame(animationFrameRef.current);
			}
		} else if (streaming) {
			// Track delta timing and length
			const now = Date.now();
			const newChars = text.length - previousContentRef.current.length;

			if (newChars > 0) {
				// Keep last 5 deltas for averaging
				deltaTimestampsRef.current.push(now);
				deltaLengthsRef.current.push(newChars);
				if (deltaTimestampsRef.current.length > 5) {
					deltaTimestampsRef.current.shift();
					deltaLengthsRef.current.shift();
				}

				// Calculate average speed from recent deltas
				if (deltaTimestampsRef.current.length >= 2) {
					const timeSpan = deltaTimestampsRef.current[deltaTimestampsRef.current.length - 1] -
						deltaTimestampsRef.current[0];
					const totalChars = deltaLengthsRef.current.reduce((sum, len) => sum + len, 0);
					const charsPerSecond = (totalChars / timeSpan) * 1000;

					// Smooth speed transitions
					speedRef.current = Math.max(minSpeed, Math.min(maxSpeed,
						speedRef.current * 0.8 + charsPerSecond * speedMultiplier
					));
				}
			}

			// Only reset if it's a completely new message
			if (text !== previousContentRef.current &&
				!previousContentRef.current.startsWith(text) &&
				!text.startsWith(previousContentRef.current)) {
				displayedContentRef.current = '';
				setDisplayedContent('');
				targetLengthRef.current = text.length;
				// Reset delta tracking on new message
				deltaTimestampsRef.current = [];
				deltaLengthsRef.current = [];
			} else {
				targetLengthRef.current = text.length;
			}

			if (!isAnimatingRef.current && text.length > displayedContentRef.current.length) {
				startAnimation();
			}
		}

		previousContentRef.current = text;
	}, [text, streaming, minSpeed, maxSpeed, speedMultiplier, startAnimation]);

	// Animation loop
	useEffect(() => {
		if (contentRef.current.length > displayedContentRef.current.length) {
			startAnimation();
		}

		return () => {
			if (animationFrameRef.current) {
				cancelAnimationFrame(animationFrameRef.current);
			}
		};
	}, [speedRef, startAnimation]); // Only recreate animation loop if speed changes

	// Cleanup on unmount
	useEffect(() => {
		return () => {
			if (animationFrameRef.current) {
				cancelAnimationFrame(animationFrameRef.current);
			}
			isAnimatingRef.current = false;
		};
	}, []);

	return displayedContent;
}
