import { relevant } from '../../api';
import classes from '../../api/classes';
import { stores } from '../../stores';
import SystemData from '../../lib/systemData';

const aiApiCall = (fnName, params) => classes.MiscTasks[fnName]({
	...params,
	apiEndpoint: SystemData.genericData.aiEndpoint,
	aiCallData: {
		id: stores.identity.userId(),
		host: relevant.baseUrl.replace(/\/$/, ''),
	},
});

export {
	aiApiCall,
};
