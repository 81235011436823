import React from 'react';
import { useHistory } from 'react-router-dom';
import { stores } from '../../stores';
import { Toast } from '../Toast/Toast';
import { Button } from '../Button/Button';

function GhostingHeader() {
	const history = useHistory();

	return (
		<Toast
			message={(
				<div className="flex gap-2">
					<div className="flex flex-col">
						<span className="font-bold">Ghosting Mode</span>
						<span>{stores.identity.name()}</span>
					</div>
					<Button onClick={() => {
						stores.identity.stopGhosting();
						history.push('/accounts');
					}}>Exit</Button>
				</div>
			)}
			status="error"
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			icon={null}
			closeable={false}
			inline
		/>
	);
}

export default GhostingHeader;
