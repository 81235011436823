import React, { useEffect, useState, useRef } from 'react';

export function useScrollState() {
	const ref = useRef<HTMLElement>(null);
	const [isTop, setIsTop] = useState(true);
	const [isBottom, setIsBottom] = useState(false);
	const [scrollX, setScrollX] = useState(0);
	const [scrollY, setScrollY] = useState(0);
	const [scrollNegY, setScrollNegY] = useState(0);
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const el = ref.current;
		if (!el) {
			return;
		}

		const handleScroll = () => {
			setIsTop(el.scrollTop === 0);
			setIsBottom(el.scrollHeight - el.clientHeight - el.scrollTop < 1);
			setScrollX(el.scrollLeft);
			setScrollY(el.scrollTop);
			// How many pixels are hidden below the bottom of the element
			setScrollNegY(el.scrollHeight - el.clientHeight - el.scrollTop);
		};

		const resizeObserver = new ResizeObserver(() => {
			handleScroll();
			setWidth(el.clientWidth);
			setHeight(el.clientHeight);
		});

		const contentObserver = new MutationObserver(() => {
			handleScroll();
		});

		el.addEventListener('scroll', handleScroll);
		resizeObserver.observe(el);
		contentObserver.observe(el, { childList: true, subtree: true });
		requestAnimationFrame(handleScroll);

		return () => {
			el.removeEventListener('scroll', handleScroll);
			resizeObserver.disconnect();
			contentObserver.disconnect();
		};
	});

	return { ref, isTop, isBottom, scrollX, scrollY, scrollNegY, width, height };
}
