const _ = require('lodash');

const Constants = {
	VERSION_NR: 1,

	LANGUAGES: {
		en: { name: 'English' },
		fi: { name: 'Finnish' },
		dk: { name: 'Danish' },
		se: { name: 'Swedish' },
		nl: { name: 'Dutch' },
	},

	CURRENCIES: {
		EUR: { name: 'Euro' },
		USD: { name: 'US Dollar' },
		DKK: { name: 'Danish Krone' },
		SEK: { name: 'Swedish Krona' },
		NOK: { name: 'Norwegian Krone' },
		GBP: { name: 'Pound Sterling' },
		CAD: { name: 'Canadian Dollar' },
		AUD: { name: 'Australian Dollar' },
	},

	MEDIA_TYPES: {
		unknown: { desc: '[Unknown]', id: 0 },
		banner: { desc: 'Banner', id: 1 },
		video: { desc: 'Video', id: 2 },
		native: { desc: 'Native', id: 3 },
		other: { desc: 'Other', id: 4 },
	},

	PAYMENT_TYPES: {
		unknown: { desc: '[Unknown]', id: 0 },
		cpc: { desc: 'CPC', id: 1 },
		other: { desc: 'CPM/Other', id: 2 },
		serverBidding: { desc: 'Open bidding', id: 3 },
		sdkMediation: { desc: 'SDK mediation', id: 4 },
	},
	DEAL_TYPES: {
		unknown: { desc: '[Unknown]', id: 0 },
		open: { desc: 'Open Deal', id: 1 },
		private: { desc: 'Private Deal', id: 2 },
		progGuaranteed: { desc: 'Programmatic Guaranteed', id: 3 },
		other: { desc: 'Other', id: 4 },
	},
	CLUSTER_INFO_URL: 'https://cdn.relevant-digital.com/resources/clusters.json',

	USER_ACCESSES: [
		'publisher@audience',
		'publisher@programmatic',
	],
};

Constants.MEDIA_TYPES_BY_ID = _.keyBy(_.entries(Constants.MEDIA_TYPES).map((
	([name, obj]) => ({ name, ...obj })
)), 'id');

Constants.PAYMENT_TYPES_BY_ID = _.keyBy(_.entries(Constants.PAYMENT_TYPES).map((
	([name, obj]) => ({ name, ...obj })
)), 'id');

Constants.DEAL_TYPES_BY_ID = _.keyBy(_.entries(Constants.DEAL_TYPES).map((
	([name, obj]) => ({ name, ...obj })
)), 'id');

module.exports = Constants;
