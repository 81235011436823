const _ = require('lodash');
const { SYSTEM_PARAM_PREFIX } = require('../misc/sharedConstants');
const COUNTRIES = require('../reportData/countries');
const GranularDimension = require('./granularDimension');
const GranularDimensionArr = require('./granularDimensionArr');

const toSysHbDim = (name) => `_${SYSTEM_PARAM_PREFIX}${name}`;

/** Default dimensions that are always used. */
const DefaultGranularDims = [
	{
		hbDim: 'floorRange',
		unitName: 'floorIdx',
		desc: 'Floor Range',
	},
	{
		hbDim: toSysHbDim('Browser name'),
		unitName: 'browser',
		checkStringIncluded: true,
		values: [
			'Chrome',
			'Safari',
			'Microsoft Edge',
			'Firefox',
		],
		desc: 'Browser',
	},
	{
		hbDim: 'country',
		unitName: 'country',
		desc: 'Country',
		convertToValidUnitValue: (code) => {
			const s = COUNTRIES[code];
			return s ? ((code.charCodeAt(1) * 256) + code.charCodeAt(0)) : null;
		},
	},
].map((d) => new GranularDimension(d));

const DefaultGranularDimsByUnitName = _.keyBy(DefaultGranularDims, 'unitName');

const granularArr = (id, unitNames) => new GranularDimensionArr(
	id,
	_.values(_.pick(DefaultGranularDimsByUnitName, unitNames)),
);

// Dimensions for the last/final report used during API data update of HBA data. These are the only
// dimensions that will be exactly correct in HBA-data (correspond exactly to API-data). Therefore,
// the most important dimensions should be added here.
const MainGranularDimArr = granularArr('main', ['floorIdx', 'browser']);

// "Less important" default dimensions
const SecondaryDimArr = granularArr('secondary', ['country']);

module.exports = {
	DefaultGranularDims,
	DefaultGranularDimsByUnitName,
	MainGranularDimArr,
	SecondaryDimArr,
};
