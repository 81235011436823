import AddBoxIcon from '@mui/icons-material/AddBox';
import {
	Box,
	DialogContent,
	DialogContentText,
	DialogTitle,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { React, useState } from 'react';
import { NewReportSelector } from '../Report/NewReportSelector';
import SimpleDialog from '../SimpleDialog';
import { SmartHelp } from '../Smarts/SmartHelp';

function CreateReportButton() {
	const [dialogIsOpen, setDialogIsOpen] = useState(false);

	return (
		<>
			<ListItemButton
				sx={{ color: 'primary.tonedDownText', pl: 9 }}
				onClick={() => {
					setDialogIsOpen(true);
				}}
			>
				<AddBoxIcon />
				<ListItemText sx={{ ml: 1 }}>
					New report
				</ListItemText>
			</ListItemButton>
			{ dialogIsOpen && (
				<SimpleDialog
					open
					onClose={() => {
						setDialogIsOpen(false);
					}}
					fullWidth
					maxWidth="lg"
				>
					<DialogTitle>
						<div className="flex items-center gap-2">
							New Report
							<SmartHelp query="reporting" question="Explain how to create a new report and the different report types ARI, HB, HB Historical, etc." />
						</div>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Create a new report or start with one of the provided templates
						</DialogContentText>
						<Box sx={{ mt: 2 }}>
							<NewReportSelector onClick={() => setDialogIsOpen(false)} />
						</Box>
					</DialogContent>
				</SimpleDialog>
			) }
		</>
	);
}

export default CreateReportButton;
