import { create } from 'zustand';

type Report = {
	id: string;
	isNew: boolean;
	tag: string;
	userID: string;
	createdDate: string;
	settings: {
		start: string;
		end: string;
		granularity: string;
		title: string;
		type: string;
	} & Record<string, any>;
};

type ReportStore = {
	currentReport: Report | null;
	setCurrentReport: (report: Report) => void;
	currentReportSettings: Record<string, any> | null;
	setCurrentReportSettings: (settings: Record<string, any>) => void,
};

export const useReportStore = create<ReportStore>((set) => ({
	currentReport: null,
	setCurrentReport: (report) => set({ currentReport: report }),
	currentReportSettings: null,
	setCurrentReportSettings: (settings) => set({ currentReportSettings: settings }),
}));
