import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
	InputLabel,
	FormControl,
	FormControlLabel,
} from '@mui/material';
import { FormOf } from '../Wrappers';
import TextField from '../TextField';
import Credentials from '../Credentials';

const availableModules = [
	'API Insights', // Unfortunately used as key in appsinstances.modules in the DB so we can't update it easily, but only visible to us anyways
	'HB Manager',
	'HB Analytics',
];

function AppInstanceForm({
	instance,
	onFieldUpdate,
	existingInstanceUrls,
	shouldSubmit,
	onSubmit,
}) {
	// Don't want to modify props directly which would result in a weird state update in the GlobalAdmin component
	// So copying instance to use as form model instead.
	const model = {
		...instance,
		credentials: { ...instance.credentials },
		modules: [...(instance.modules || [])],
	};

	const { modules } = instance;

	return (
		<div>
			{ model
				&& (
					<FormOf
						model={model}
						shouldSubmit={shouldSubmit}
						onFieldUpdate={() => (
							onFieldUpdate(model)
						)}
						onSpecialSubmit={onSubmit}
						content={({ field }) => (
							<>
								<TextField
									label="Instance name"
									{...field('name')}
									margin="normal"
									required
									fullWidth
								/>
								<TextField
									label="Instance API URL"
									{...field('url')}
									margin="normal"
									required
									url
									fullWidth
									satisfy={{
										valid: (value) => !existingInstanceUrls.includes(value),
										message: 'Instance API URL already exists',
									}}
								/>
								<FormControl sx={{
									width: '100%',
									mb: 2.5,
									mt: 2,
								}}
								>
									<InputLabel id="modules-label">Modules</InputLabel>
									<Select
										labelId="modules-label"
										id="modules-list"
										label="Modules"
										renderValue={(selected) => selected.join(', ')}
										{...field('modules')}
										multiple
									>
										{availableModules.map((module) => (
											<MenuItem key={module} value={module}>
												<Checkbox checked={modules?.indexOf(module) > -1} />
												<ListItemText primary={module} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControlLabel
									sx={{
										mb: 2,
									}}
									label="Active"
									control={(
										<Checkbox
											checked={instance.isActive}
											{...field('isActive')}
											onChange={(_, checked) => {
												const { onChange, name } = field('isActive');
												onChange({ target: { name, value: checked } });
											}}
										/>
									)}
								/>
								<Credentials
									model={model.credentials}
									field={(name) => field(`credentials.${name}`)}
								/>
							</>
						)}
					/>
				)}
		</div>
	);
}

AppInstanceForm.propTypes = {
	instance: PropTypes.shape({
		url: PropTypes.string,
		credentials: PropTypes.shape({
			username: PropTypes.string,
			password: PropTypes.string,
		}),
		modules: PropTypes.arrayOf(PropTypes.string),
		isActive: PropTypes.bool,
	}).isRequired,
	existingInstanceUrls: PropTypes.arrayOf(PropTypes.string),
	onFieldUpdate: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	shouldSubmit: PropTypes.bool.isRequired,
};

AppInstanceForm.defaultProps = {
	existingInstanceUrls: [],
};

export default AppInstanceForm;
