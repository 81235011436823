import _ from 'lodash';
import React from 'react';
import pako from 'pako';
import config from 'appconfig'; // eslint-disable-line import/no-unresolved
import authToken from '../lib/auth-token';
import MiscUtils from '../lib/miscUtils';
import { stores } from '../stores';

const apis = {
	extraHeaders: {},
	// Use the listeners() below to listen for any api call
	onSuccess: MiscUtils.listeners(),
	onError: MiscUtils.listeners(),
};

const MIN_COMPRESS_LEN = 64 * 1024;

const setCompressionOptions = (options) => {
	if (!options.body || options.body.length < MIN_COMPRESS_LEN || window.systest) {
		return;
	}
	options.body = pako.deflate(options.body);
	options.headers['Content-Encoding'] = 'deflate';
};

export function createApiInterface(baseUrl) {
	function request(method, url, body = null, { headers = null } = {}) {
		const options = { method };
		const fullUrl = `${baseUrl.replace(/\/+$/g, '')}/${url.replace(/^\/+/g, '')}`;
		if (body) {
			try {
				options.body = JSON.stringify(body);
			} catch (e) {
				options.body = JSON.stringify(_.cloneDeepWith(body, (val) => {
					if (val instanceof React.Component) {
						return null;
					}
					return undefined;
				}));
			}
		}
		options.headers = {
			...apis.extraHeaders,
			...headers,
		};
		options.headers['Content-Type'] = 'application/json';
		options.credentials = 'include';

		// TODO: Setting Authorization header shouldn't be necessary
		const authenticationToken = authToken.get();
		if (authenticationToken) {
			options.headers['Authorization'] = authenticationToken; // eslint-disable-line
		}

		authToken.syncToCookie();

		setCompressionOptions(options);

		return (window.systest ? systest.fetch : fetch)(fullUrl, options)
			.then((response) => response.json())
			.then((json) => {
				if (json.success) {
					apis.onSuccess.notify(json);
					return json;
				}
				apis.onSuccess.notify(json);
				if (json.error === 'AUTH_ERROR') {
					stores.identity.logout();
					window.location = `${window.location.origin}/login`;
				}
				throw json;
			});
	}

	return {
		get: _.partial(request, 'GET', _, null, _),
		post: _.partial(request, 'POST', _, _, _),
		put: _.partial(request, 'PUT', _, _, _),
		delete: _.partial(request, 'DELETE', _, null, _),
		baseUrl,
	};
}

_.each(config.apis, (value, key) => { apis[key] = createApiInterface(value); });

export default apis;
// This destroys the genericism, but I think this is legacy stuff anyway
export const {
	relevant, aws, onSuccess, onError,
} = apis;
