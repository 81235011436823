import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Typography,
	Paper,
	Box,
	Grid,
	Card,
	CardContent } from '@mui/material';
import { setDummyCredentialsForHbm } from 'relevant-shared/misc/credentialsFieldInfo';
import Form from '../../containers/Form';
import ExpandSelector from '../ExpandSelector';
import PricelistEditor from '../PricelistEditor';
import GmailAccountEditor from '../GmailAccountEditor';
import ExternalDataImport from '../ExternalDataImport';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import { DemoHide, FormOf } from '../Wrappers';
import Credentials from '../Credentials';
import ImageWithFallback from '../ImageWithFallback';
import Select from '../Select';
import DateUtils from '../../lib/dateUtils';
import DatePicker from '../DatePicker';
import CutField from '../CutField';
import ApiUsageSettings from '../ApiUsageSettings';
import SystemData from '../../lib/systemData';
import FieldArray from '../FieldArray';
import GoogleAnalyticsProfileSelect from '../GoogleAnalyticsProfileSelect';
import ReactUtils from '../../lib/reactUtils';
import JobButton from '../JobButton';
import { sspLogo, fallbackSspLogo } from '../../lib/sspLogos';

const { AllDimensions } = require('relevant-shared/mappingDimensions/allDimensionArrays');

const customRenderers = {};

@Form
class ExternalSystemEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.originalTypesCopy = _.cloneDeep(props.types);
	}

	onTypeChanged(val) {
		const {
			model, changeModel, types, initObj, form,
		} = this.props;
		if (val !== model.type) {
			let obj;
			if (initObj && initObj.type === val) {
				obj = initObj;
			} else {
				obj = types.find((type) => type.type === val);
			}
			// copy shared fields (that shouldn't change when changing field)
			['id', '_id', 'name', 'active'].forEach((fld) => {
				obj[fld] = model[fld];
			});
			form.resetErrors();
			changeModel(obj);
		}
	}

	onSubSystemTypeChanged(type, { subSystemObjectField }) {
		const { model } = this.props;
		if (!type) {
			model[subSystemObjectField] = {}; // reset settings when chaning to "(none)"
			return;
		}
		// Create default settings, but only these unique to the sub system (SSP)
		let newSysObj = _.find(this.originalTypesCopy, { type });
		const BaseType = newSysObj.constructor.getParent();
		const baseKeys = Object.keys(new BaseType());
		newSysObj =	_.cloneDeep(_.omit({ ...newSysObj }, baseKeys));
		setDummyCredentialsForHbm(newSysObj.credentials);
		model[subSystemObjectField] = newSysObj;

		this.setState({ subSystemChanged: true }); // Will expand settings
	}

	renderDataCorrections() {
		const { model, field, form } = this.props;
		const { CURRENCIES } = SystemData.genericData;
		return (
			<ExpandSelector
				title="eCPM corrections for direct campaigns"
				selected={model.dataCorrections}
				form={form}
			>
				<FieldArray
					model={model}
					field={field}
					form={form}
					name="dataCorrections"
					update={() => this.forceUpdate()}
					createNew={() => ({
						advName: '',
						ecpm: '',
						currency: null,
						start: null,
						end: null,
						method: 'fixed',
					})}
					render={(fld, { start, end, method }) => (
						<Grid container spacing={3}>
							<Grid item xs={4}>
								<TextField
									label="Advertiser name"
									{...fld('advName')}
									required
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									label={method === 'fixed' ? 'Fixed CPM' : 'Multiply CPM'}
									{...fld('ecpm')}
									required
									float
									between={{ low: 0 }}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<Select
									label="Currency"
									{...fld('currency')}
									nonSelected="(none)"
									required
									items={_.map(CURRENCIES, (v, k) => ({ label: `${v.name} (${k})`, value: k }))}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<Select
									label="Method"
									{...fld('method')}
									required
									items={[
										{ label: 'Fixed CPM', value: 'fixed' },
										{ label: 'Multiply CPM', value: 'multi' },
									]}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<DatePicker
									floatingLabelText="Start date"
									help="Start date"
									maxDate={end ? new Date(end) : undefined}
									autoOk
									required
									{...fld('start')}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<DatePicker
									floatingLabelText="End date"
									help="End date"
									minDate={start ? new Date(start) : undefined}
									autoOk
									required
									{...fld('end')}
									fullWidth
								/>
							</Grid>
						</Grid>
					)}
				/>
			</ExpandSelector>
		);
	}

	renderImportFilter(importFilterName, title) {
		const { model, field, form } = this.props;
		return (
			<ExpandSelector
				title={title}
				selected={model[importFilterName].filters}
				form={form}
			>
				<FieldArray
					model={model[importFilterName]}
					field={(name) => field(`${importFilterName}.${name}`)}
					form={form}
					name="filters"
					update={() => this.forceUpdate()}
					createNew={() => new SystemData.models.ImportParameterFilter()}
					render={(fld) => (
						<Grid container spacing={3}>
							<Grid item xs={4}>
								<TextField
									label="String to match"
									{...fld('matchString')}
									required
									fullWidth
								/>
							</Grid>
						</Grid>
					)}
				/>
			</ExpandSelector>
		);
	}

	renderKeywordExport() {
		const { model, form } = this.props;
		const pushKeywords = async () => {
			try {
				await model.pushKeywords();
			} finally {
				const updatedObj = await model.constructor.getParent().get(model.id);
				form.update(() => {
					model.keywordExportStatus = updatedObj.keywordExportStatus;
				});
			}
		};
		const STATUSES = { true: ['green', 'SUCCESS'], false: ['red', 'FAILED'], undefined: ['gray', 'UNKNOWN'] };
		const [color, desc] = STATUSES[model.keywordExportStatus];
		return (
			<Grid container spacing={3}>
				<Grid item>
					<JobButton
						label="Export prebid configuration ID key values"
						fn={pushKeywords}
						style={{ float: 'left' }}
					/>
				</Grid>
				<Grid item>
					<i>Current export status: </i>
					<b style={{ color }}>{desc}</b>
				</Grid>
			</Grid>
		);
	}

	renderSubSystem({ subSystemObjectField, value }, selectField, options) {
		const { model, form, field } = this.props;
		const { subSystemChanged } = this.state;
		if (!subSystemObjectField || !model[selectField]) {
			return null; // Not a sub system at all
		}
		const subSys = model[subSystemObjectField];
		if (!subSys || !Object.keys(subSys).find((k) => options[k])) {
			return null; // no fields to show
		}
		return (
			<ExpandSelector
				title={`${value[model[selectField]]} settings`}
				form={form}
				expanded={!!subSystemChanged}
			>
				<ExternalSystemEdit
					{...this.props}
					field={ReactUtils.subFld(field, subSystemObjectField)}
					model={subSys}
					types={_.cloneDeep(this.originalTypesCopy)}
					onlyOptions
				/>
			</ExpandSelector>
		);
	}

	renderOptionsInternal({ availOptions }) {
		const seenGroups = {};
		const res = [];
		const options = Object.entries(availOptions);
		const byGroup = _.groupBy(options, ([, v]) => v.group);
		options.forEach(([k, v]) => {
			if (!v.group) {
				res.push((
					<Grid item xs={12} key={k}>{v(k)}</Grid>
				));
			} else if (v.group && !seenGroups[v.group]) {
				seenGroups[v.group] = true;
				res.push((
					<Grid item xs={12} key={k}>
						<ExpandSelector title={v.group}>
							<Grid container spacing={3}>
								{byGroup[v.group].map(([key, fn]) => (
									<Grid item xs={12} key={key}>{fn(key)}</Grid>
								))}
							</Grid>
						</ExpandSelector>
					</Grid>
				));
			}
		});
		return <Grid container item spacing={3}>{res}</Grid>;
	}

	renderOptions({ model, field, form }) {
		const { formCollection, model: system } = this.props;
		const { CURRENCIES } = SystemData.genericData;
		let options;

		const txtFld = (name, label, opts) => {
			const p = {
				label, required: true, fullWidth: true, ...field(name), ...opts,
			};
			return React.createElement(TextField, p);
		};

		const textFld = (label, opts) => ((name) => txtFld(name, label, opts || {}));

		const textFldWithImg = (label, opts) => ((name) => {
			return (
				<Box display="flex" alignItems="center">
					<ImageWithFallback
						src={model[name] || sspLogo(model)}
						fallback={fallbackSspLogo}
						alt={model.name}
						style={{ height: 40, marginRight: 10 }}
					/>
					{txtFld(name, label, opts)}
				</Box>
			);
		});

		const cutFld = (label) => ((name) => (
			<CutField
				{...field(name)}
				label={label}
				required
				fullWidth
			/>
		));

		const boolFld = (label) => ((name) => (
			<Checkbox
				{...field(name)}
				label={label}
			/>
		));

		const singleOption = (name, obj) => {
			if (name === 'csvUrlContains' && !model.csvIsLink) {
				return null;
			}
			if (_.isObject(model[name] || obj.dataObject)) {
				return (
					<FormOf
						key={name}
						model={model[name] || obj.dataObject}
						formCollection={formCollection}
						content={(p) => (obj.customRenderer ? customRenderers[obj.customRenderer]({
							parent: model, system, ...p,
						}) : (
							<ExpandSelector title={obj.description}>
								{this.renderOptions(_.pick(p, ['model', 'form', 'field']))}
							</ExpandSelector>
						))}
					/>
				);
			}
			if (obj.isUiContainer) {
				return (
					<ExpandSelector title={obj.description}>
						{/* eslint-disable-next-line no-use-before-define */}
						{optionsOfObj(availOptions(obj.value))}
					</ExpandSelector>
				);
			}
			if (obj.value && !obj.isBool) {
				return (
					<>
						<Select
							key={name}
							nonSelected={obj.nonSelected}
							{...field(name)}
							label={obj.description}
							required={!!obj.required}
							items={Object.entries(obj.value).map(([k, v]) => ({
								label: v.description || v,
								value: k,
							}))}
							fullWidth
							margin="normal"
							preReaction={(newVal) => {
								if (obj.subSystemObjectField) {
									this.onSubSystemTypeChanged(newVal, obj);
								}
							}}
						/>
						{this.renderSubSystem(obj, name, options)}
					</>
				);
			}
			if (obj.isBool) {
				return (
					<div key={name}>
						<Checkbox
							{...field(name)}
							label={obj.description}
							fullWidth
							help={obj.helpText}
						/>
						{/* eslint-disable-next-line no-use-before-define */}
						{obj.value && model[name] && optionsOfObj(obj.value)}
					</div>
				);
			}
			if (obj.isImporter) {
				return !model.isNew && (
					<>
						<ExpandSelector title={obj.description || 'Import data'}>
							<ExternalDataImport
								model={this.props.model}
								importFn={(modelObj, from, to, fromHour, toHour) => (
									modelObj[obj.importFn || 'importData']({
										from,
										to,
										...(obj.hasHourSetting ? { fromHour, toHour } : {}),
									})
								)}
								{..._.pick(obj, ['hasHourSetting', 'fromDate', 'toDate', 'minDate', 'maxDate'])}
							/>
						</ExpandSelector>
					</>
				);
			}
			return (
				<TextField
					key={name}
					{...field(name)}
					fullWidth
					label={obj.description}
					help={obj.helpText}
					required={!!obj.required}
					{...obj.props}
				/>
			);
		};

		const availOptions = (opts) => {
			const selectors = {
				...model.fieldSelectorOptions,
				..._.pickBy(opts, (opt) => _.values(opt?.value).find((v) => v?.fields?.length)),
			};
			if (_.isEmpty(selectors)) {
				return opts;
			}
			const res = { ...opts };
			_.forOwn(selectors, ({ value }, fldName) => {
				_.forOwn(value, ({ fields }, optName) => {
					if (model[fldName] !== optName) {
						fields.forEach((fld) => {
							delete res[fld];
						});
					}
				});
			});
			return res;
		};

		const optionsOfObj = (optObj) => this.renderOptionsInternal({
			availOptions: _.mapValues(availOptions(optObj), (obj, name) => (
				Object.assign(() => singleOption(name, obj), _.pick(obj, 'group'))
			)),
		});

		const optionsOf = (fldName) => () => optionsOfObj(availOptions(model[fldName]));

		options = {
			customLogoPath: textFldWithImg('Custom Logo URL', { required: false }),
			dealsPerc: cutFld('Deals(%)'),
			openRtbPerc: cutFld('OpenRTB(%)'),
			directPerc: cutFld('Direct(%)'),
			preferredDealsPerc: cutFld('Preferred deals(%)'),
			programmaticGuaranteedPerc: cutFld('Programmatic guaranteed(%)'),
			useDefaultReportCurrency: boolFld('Use default report currency'),
			pbReportConfigIdKey: textFld(
				'Custom AdServer key for prebid configuration ID reporting',
				{ required: false },
			),
			pbReportConfigNameKey: textFld(
				'Custom AdServer key for prebid configuration name reporting',
				{ required: false },
			),
			keywordsMustBePushed: () => model.keywordsMustBePushed && this.renderKeywordExport(),
			defaultReportCurrency: model.useDefaultReportCurrency && (() => (
				<Select
					{...field('defaultReportCurrency')}
					label="Default report currency"
					items={_.keys(CURRENCIES).map((c) => ({ label: c, value: c }))}
					fullWidth
				/>
			)),
			publisherBankAccount: model.publisherId && model.type !== 'GoogleAnalytics'
				&& boolFld('Revenue is paid from SSP to publisher'),
			ignoreInvoicing: boolFld('Exclude from invoicing'),
			// systemWideCost: numFld('System wide cost (€)'),
			accountId: textFld('Account ID'),
			apiHostName: textFld('API Host Name'),
			apiUrl: textFld('API URL'),
			customBidderName: textFld('Custom bidder name(s), comma separated', { required: false }),
			alternateBidderCode: textFld('Alternate bidder code(s), comma separated', { required: false }),
			forwardIp: textFld('X-Forwarded-For Ip address'),
			networkCode: textFld('Network Code'),
			deliveryTargetingName: textFld('Delivery targeting name (e.g. "dmp71")', { required: false }),
			includeDirectRevenue: boolFld('Include direct campaigns'),
			includeExchangeBiddingRevenue: boolFld('Include Open Bidding and Mediation revenue'),
			importZeroRevs: boolFld('Import Sold impressions with revenue 0'),
			isUsingRtbPlus: boolFld('Use RTB+'),
			usePacificTime: boolFld('Import using PDT time zone'),
			useFullPaths: boolFld('Use full ad unit paths (ad unit codes are not unique)'),
			usePlacementNameAsId: boolFld('Use placement name as ID'),
			splitOthersToDomains: boolFld('Split \'Others\' placement to special domain placements'),
			disableFromHb: () => (
				<Checkbox
					{...field('disableFromHb')}
					label="Disable from headerbidding"
					help="WARNING: this setting will force-disable headerbidding for this SSP, ignoring any other settings"
				/>
			),
			...Object.assign(...[
				['sspFilterFld', 'Data to exclude (advertisers etc)'],
				['sspFilterPlacFld', 'Data to exclude from Viewability% / Est. SSP in [only for special cases]'],
			].map(([fld, group]) => _.mapValues(_.keyBy(_.filter(AllDimensions, 'importExcludeFilter'), fld), (dim) => (
				Object.assign(textFld(dim.importExcludeDescription, {
					required: false,
					multiLine: true,
				}), { group })
			)))),
			maxDefaultImportDays: textFld(
				'Max auto import days',
				{ required: false, integer: true, between: { low: 0 } },
			),
			maxMonthlyDataUsage: () => <ApiUsageSettings model={model} field={field} />,
			selectOptions: optionsOf('selectOptions'),
			fieldSelectorOptions: optionsOf('fieldSelectorOptions'),
			timezone: !model.timeZonesSupported ? textFld('Time zone', { required: false }) : () => (
				<Select
					{...field('timezone')}
					label="Time zone"
					nonSelected="(default)"
					items={model.timeZonesSupported.map((tz) => ({ label: tz, value: tz }))}
					fullWidth
				/>
			),
			pricingModel: () => (
				<Select
					{...field('pricingModel')}
					label="Pricing model for multi-segment usage"
					items={_.map(['SUM', 'MAX'], (ty) => ({ label: ty, value: ty }))}
					fullWidth
				/>
			),
			dataCorrections: () => this.renderDataCorrections(),
			directCampaignsImportFilter: () => this.renderImportFilter(
				'directCampaignsImportFilter',
				'Only include line items for direct campaigns containing these string(s) in the name',
			),
			preferredDealsImportFilter: () => this.renderImportFilter(
				'preferredDealsImportFilter',
				'Only include line items for preferred deals containing these string(s) in the name',
			),
			hasGmailAccount: () => (
				<div>
					<GmailAccountEditor
						model={model.gmailAccount}
						field={(name) => field(`gmailAccount.${name}`)}
						form={form}
						onSubmit={() => {}}
					/>
					<Card>
						<CardContent>
							<Typography variant="h2">
								Mails to read
							</Typography>
							<TextField
								{...field('mailFrom')}
								label="Sender email address"
								required
								emails
								fullWidth
								margin="normal"
							/>
							<DemoHide cond={!model.isNew}>
								<TextField
									{...field('mailSubjectContains')}
									label="Mail subject must contain"
									fullWidth
									margin="normal"
								/>
							</DemoHide>
						</CardContent>
					</Card>
				</div>
			),
			isGoogleAccount: () => (
				<GmailAccountEditor
					{...({ model, field, form })}
					onSubmit={(token) => this.setState({ authToken: token })}
				/>
			),
			credentials: () => (
				<Credentials
					model={model.credentials}
					field={(name) => field(`credentials.${name}`)}
					refreshTokenFn={model.performTokenRefresh && (async () => {
						const flds = await model.performTokenRefresh();
						form.setVals(_.mapKeys(flds, (v, k) => `credentials.${k}`));
					})}
				/>
			),

			hasPricelist: () => (
				<ExpandSelector
					title="Pricelist"
				>
					<PricelistEditor
						priceListContext={this.props.priceListContext}
						field={field}
					/>
				</ExpandSelector>
			),
		};

		return this.renderOptionsInternal({
			availOptions: _.pickBy(availOptions(options), (v, k) => v && model[k] !== undefined),
		});
	}

	renderFull() {
		const {
			field,
			form,
			model,
			types,
			desc,
		} = this.props;
		return (
			<Paper>
				<Box padding={2}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h2">
								{`${desc} Info`}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Select
								{...field('type')}
								onChange={(ev) => this.onTypeChanged(ev.target.value)}
								label="Type"
								items={_.sortBy(types.map((type) => ({
									label: type.friendlyTypeName,
									value: type.type,
								})), 'label')}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...field('name')}
								label="Name"
								required
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Checkbox
								{...field('active')}
								label="Active"
							/>
						</Grid>
						{!model.isNew && !model.hasNoImport
						&& (
							<Grid item xs={12}>
								<ExternalDataImport
									model={model}
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<DatePicker
								floatingLabelText="Never import before date"
								maxDate={DateUtils.today()}
								autoOk
								canClear
								{...field('neverImportBefore')}
								fullWidth
							/>
						</Grid>
						{this.renderOptions({ model, field, form })}
						{ model.type === 'GoogleAnalytics' && (
							<Grid item xs={12}>
								<Checkbox
									label="Import all existing profiles and automatically add new ones"
									{...field('importAllAndNewProfiles')}
								/>
								<GoogleAnalyticsProfileSelect
									isVisible={!field('importAllAndNewProfiles').value}
									isNew={model.isNew}
									savedProfileIds={field('profileIds').value}
									analyticsSystemId={model._id}
									onChange={(newValue) => {
										const { onChange, name } = field('profileIds');
										onChange({ target: { name, value: newValue } });
									}}
									authToken={this.state.authToken}
								/>
							</Grid>
						)}
					</Grid>
				</Box>
			</Paper>
		);
	}

	render() {
		const { onlyOptions } = this.props;
		if (onlyOptions) {
			return (
				<Grid container spacing={3}>
					{this.renderOptions(this.props)}
				</Grid>
			);
		}
		return this.renderFull();
	}
}

ExternalSystemEdit.registerCustomRenderer = (name, fn) => {
	customRenderers[name] = fn;
};

ExternalSystemEdit.wrappedComponent.propTypes = {
	types: PropTypes.array.isRequired,
	initObj: PropTypes.object,
	model: PropTypes.object.isRequired,
	formCollection: PropTypes.object.isRequired,
	changeModel: PropTypes.func.isRequired,
	field: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	desc: PropTypes.string.isRequired,
	id: PropTypes.string,
	priceListContext: PropTypes.object.isRequired,
	onlyOptions: PropTypes.bool,
};

ExternalSystemEdit.wrappedComponent.defaultProps = {
	initObj: null,
	id: null,
	onlyOptions: false,
};

export default ExternalSystemEdit;
