import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import Audience from './Audience';
import Programmatic from './Programmatic';
import Administrator from './Administrator';
import { stores } from '../stores';
import Sidebar from '../components/Sidebar';
import Topbar from '../components/TopBar';
import SidebarProvider from '../providers/SidebarProvider';
import TopBarProvider from '../providers/TopBarProvider';
import ReportsProvider from '../providers/ReportsProvider';
import TitleProvider from '../providers/TitleProvider';
import { getProductType, PRODUCT_TYPE } from '../lib/access';
import BrowserUtils from '../lib/browserUtils';
import PageFooter from '../components/PageFooter';
import { Smarts } from '../components/Smarts/Smarts';
import clsx from 'clsx';
import { useAiStore } from '../stores/AiStore';
import SystemData from '../lib/systemData';

export default function Main({ children }) {
	// Audience or yield
	// Only relevant for non-admin users that has both audience and yield (programmatic).
	const location = useLocation();
	const initialSelectedProduct = location.pathname.startsWith('/audience') ? 'audience' : 'yield';
	const [selectedProduct, setSelectedProduct] = useState(initialSelectedProduct);
	const productType = getProductType();

	useEffect(() => {
		// Set selected product based on current path, for 'full' users.
		if (productType === PRODUCT_TYPE.FULL) {
			if (location.pathname.startsWith('/audience')) {
				setSelectedProduct('audience');
			} else {
				setSelectedProduct('yield');
			}
		}
	}, [location.pathname, productType]);

	if (location.pathname.startsWith('/public')) {
		return null;
	}
	if (!stores.identity.value.authenticated) {
		if (location.pathname !== '/login' && location.pathname !== '/') {
			const to = BrowserUtils.makeQs('/login', {
				redirect: location.pathname + location.search,
			});
			return <Redirect to={to} />;
		}
		if (location.pathname === '/login' && location.search.includes('redirect')) {
			return <Redirect to={`${location.pathname}${location.search}`} />;
		}
		return <Redirect to="/login" />;
	}

	const canSelectProduct = productType === PRODUCT_TYPE.FULL;
	const isAudience = productType === PRODUCT_TYPE.AUDIENCE
		|| (productType === PRODUCT_TYPE.FULL && selectedProduct === 'audience');
	const onSelectProduct = canSelectProduct ? (product) => setSelectedProduct(product) : undefined;

	return (
		<>
			<PageFooter />
			<ReportsProvider productType={productType}>
				<SidebarProvider>
					<TopBarProvider>
						<TitleProvider>
							<Topbar
								productType={productType}
								onSelectProduct={onSelectProduct}
							/>
							<Sidebar
								productType={productType}
								isAudience={isAudience}
								onSelectProduct={onSelectProduct}
							/>
							<div className="flex flex-row">
								{
									productType === 'admin' && (
										<Administrator>
											{ children }
										</Administrator>
									)
								}
								{
									productType === 'full' && (
										<Programmatic
											productType="full"
										>
											{ children }
											<Audience>
												<Redirect to={selectedProduct === PRODUCT_TYPE.AUDIENCE
													? '/audience/dashboard' : '/dashboard'}
												/>
											</Audience>
										</Programmatic>
									)
								}
								{
									productType === 'programmatic' && (
										<Programmatic>
											{ children }
											<Redirect to="/dashboard" />
										</Programmatic>
									)
								}
								{
									productType === 'audience' && (
										<Audience includeReportRoutes>
											{ children }
											<Redirect to="/audience/dashboard" />
										</Audience>
									)
								}
								<SmartsPlaceholder />
							</div>
							{SystemData.genericData.aiEnabled && stores.identity.isAdministrator() && <Smarts />}
						</TitleProvider>
					</TopBarProvider>
				</SidebarProvider>
			</ReportsProvider>
		</>
	);
}

Main.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

Main.defaultProps = {
	children: null,
};

function SmartsPlaceholder() {
	const {
		isPinnedAndOpen: isAiPinnedAndOpen,
		pinnedWidth: aiPinnedWidth,
	} = useAiStore();

	return (
		<div
			id="main-ai-placeholder"
			className="flex-none transition-all duration-400"
			style={{
				width: isAiPinnedAndOpen ? 'var(--ai-width)' : 0,
				'--ai-width': `${aiPinnedWidth}px`,
			}}
		/>
	);
}
