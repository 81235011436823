import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

export function isInternalUrl(url: string) {
	return !url.startsWith('http') || url.startsWith(window.location.origin);
}

type LinkProps = {
	// Router link
	to?: string;
	// External link
	href?: string;
	unstyled?: boolean;
	className?: string;
	children: React.ReactNode;
};

export const Link = forwardRef(({ to, href, unstyled, className, children, ...rest }: LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>, ref: React.Ref<HTMLAnchorElement>) => {
	// props with ref
	const props: React.HTMLAttributes<HTMLAnchorElement> = {
		className: clsx(
			!unstyled && 'text-sm font-medium underline text-cherry-600 hover:text-cherry-700 focus:text-cherry-700 cursor-pointer',
			rest.onClick && !to && !href && 'no-underline font-normal', // Hide underline for buttons masquerading as links (legacy)
			(href === children?.toString()) && 'break-all', // Break long URLs anywhere
			className,
		),
		...rest,
	};

	return to ? (
		<RouterLink to={to} role="link" {...props} ref={ref}>{children}</RouterLink>
	) : (
		<a href={href} role="link" {...props} ref={ref}>{children}</a>
	);
});
